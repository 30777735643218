import { Button, CircularProgress } from '@material-ui/core';
import React, { useContext } from 'react';

import Center from '../../widgets/Center';
import DesignerMyProjects from './DesignerMyProjects';
import Error404 from '../404/404';
import { UserContext } from '../../App';

function MyProjects() {
  const userInfo = useContext(UserContext);
  return (
    <>
      {userInfo.user_role ? (
        userInfo.user_role === 'designer' ? (
          <DesignerMyProjects />
        ) : (
          <Error404 />
        )
      ) : (
        <Center>
          <CircularProgress />
        </Center>
      )}
    </>
  );
}

export default MyProjects;
