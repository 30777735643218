import '../css/Sidebar.css';

import {
  CloseOutlined,
  DashboardOutlined,
  DoneAllOutlined,
  HourglassEmptyOutlined,
  ListAltOutlined,
  MonetizationOnOutlined,
  MoneyOutlined,
  NaturePeopleOutlined,
  PaletteOutlined,
  PaymentOutlined,
  PeopleOutlined,
  PlaylistAddCheckOutlined,
  WorkOutline,
} from '@material-ui/icons';
import React, { useContext } from 'react';

import { Avatar } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import SideBarMenuItem from './SidebarMenuItem';
import { UserContext } from '../App';
import db from '../firebase/config';
import textLogo from '../assets/vista-text.png';

const Sidebar = (props) => {
  const user = db.auth().currentUser;
  const userInfo = useContext(UserContext);

  return (
    <div className={props.className}>
      <div className="sidebar__body">
        <div className="sidebar__infoContainer">
          <div className="sidebar__closeIcon">
            <CloseOutlined onClick={props.onCrossClick} />
          </div>
          <div className="sidebar_logoContainer">
            <img src={textLogo} />
          </div>

          <div className="sidebar__profile u-animate-fade">
            {userInfo.avatar_url ? (
              <Avatar
                style={{ width: '24px', height: '24px' }}
                src={userInfo.avatar_url}
              />
            ) : (
              <Avatar style={{ width: '24px', height: '24px' }}>
                <b style={{ fontWeight: 'bold' }}>
                  {user.email[0].toUpperCase()}
                </b>
              </Avatar>
            )}
            <div className="profile__loggedInfo u-animate-fade">
              <span className="profile__userName">{userInfo.name}</span>
              <span className="profile__userText">{userInfo.email}</span>
            </div>
          </div>
        </div>
        <h3 className="sidebar__menuTitle">VISTA CONCEPTS</h3>
        <NavLink
          to="/dashboard"
          activeClassName="active"
          style={{ textDecoration: 'none' }}
        >
          <SideBarMenuItem Icon={DashboardOutlined} title="Dashboard" />
        </NavLink>

        {/* {userInfo.user_role && userInfo.user_role === 'client' && (
          <>
            <h3 className="sidebar__menuTitle">Management</h3>
            <NavLink
              to="/users"
              activeClassName="active"
              style={{ textDecoration: 'none' }}
            >
              <SideBarMenuItem Icon={PeopleOutlined} title="Company Projects" />
            </NavLink>
          </>
        )} */}
        {userInfo.user_role && userInfo.user_role === 'designer' && (
          <>
            <h3 className="sidebar__menuTitle">Management</h3>
            <NavLink
              to="/designer/my-projects"
              activeClassName="active"
              style={{ textDecoration: 'none' }}
            >
              <SideBarMenuItem Icon={ListAltOutlined} title="My All Projects" />
            </NavLink>
          </>
        )}
        <>
          <h3 className="sidebar__menuTitle">Work Flow</h3>
          {userInfo.email && userInfo.user_role !== 'designer' && (
            <>
              <NavLink
                to="/projects/new"
                activeClassName="active"
                style={{ textDecoration: 'none' }}
              >
                <SideBarMenuItem Icon={WorkOutline} title="New Projects" />
              </NavLink>
            </>
          )}
          <NavLink
            to="/projects/queue"
            activeClassName="active"
            style={{ textDecoration: 'none' }}
          >
            <SideBarMenuItem
              Icon={HourglassEmptyOutlined}
              title="Design Queue"
            />
          </NavLink>
          <NavLink
            to="/projects/approved"
            activeClassName="active"
            style={{ textDecoration: 'none' }}
          >
            <SideBarMenuItem
              Icon={PlaylistAddCheckOutlined}
              title="Approved Projects"
            />
          </NavLink>
          <NavLink
            to="/projects/finished"
            activeClassName="active"
            style={{ textDecoration: 'none' }}
          >
            <SideBarMenuItem Icon={DoneAllOutlined} title="Finished Projects" />
          </NavLink>
        </>

        {/* {userInfo.user_role &&
          (userInfo.isAdmin || userInfo.user_role !== 'designer') && (
            <>
              <h3 className="sidebar__menuTitle">Payments</h3>
              <NavLink
                to="/all-payments"
                activeClassName="active"
                style={{ textDecoration: 'none' }}
              >
                <SideBarMenuItem
                  Icon={MonetizationOnOutlined}
                  title="All Payments"
                />
              </NavLink>
            </>
          )} */}

        {userInfo.user_role && userInfo.user_role === 'admin' && (
          <>
            <h3 className="sidebar__menuTitle">Company</h3>
            <NavLink
              to="/landscape-clients"
              activeClassName="active"
              style={{ textDecoration: 'none' }}
            >
              <SideBarMenuItem
                Icon={NaturePeopleOutlined}
                title="Landscape Clients"
              />
            </NavLink>
            <NavLink
              to="/designers"
              activeClassName="active"
              style={{ textDecoration: 'none' }}
            >
              <SideBarMenuItem Icon={PaletteOutlined} title="Designers" />
            </NavLink>
          </>
        )}

        {userInfo.user_role && userInfo.user_role === 'admin' && (
          <>
            <h3 className="sidebar__menuTitle">Management</h3>
            <NavLink
              to="/users"
              activeClassName="active"
              style={{ textDecoration: 'none' }}
            >
              <SideBarMenuItem Icon={PeopleOutlined} title="All Users" />
            </NavLink>
          </>
        )}

        <div style={{ marginTop: '44px' }}>
          {/* <a href="https://vistaconcepts.co" target="_blank">
            <p style={{ color: 'white', textAlign: 'center' }}>
              vistaconcepts.co{' '}
            </p>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
