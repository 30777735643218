import 'nprogress/nprogress.css';

import React, { useEffect } from 'react';

import { Route } from 'react-router-dom';
import nprogress from 'nprogress';

function PRoute(props) {
  useEffect(() => {
    nprogress.start();
    setTimeout(() => {
      nprogress.done();
    }, 1200);
  }, []);
  return <Route {...props} />;
}

export default PRoute;
