import { Button, IconButton } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../App';

import '../../css/dashboard.css';
import '../../App.css';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { PayPalButton } from 'react-paypal-button-v2';
import { useHistory } from 'react-router-dom';

import db from '../../firebase/config';
import { auth } from 'firebase';

const Payments = () => {
  const userInfo = useContext(UserContext);

  const [isBuyButtonClicked, setBuyButtonClicked] = useState(false);
  const [amount, setAmount] = useState(1);

  const [price, setPrice] = useState('0');
  const [item, setItem] = useState('N/A');

  const history = useHistory();

  return (
    <>
      <div className="body">
        <h2
          style={{
            marginTop: '12px',
            marginBottom: '24px',
            fontWeight: 'bold',
            color: '#949494 !important',
          }}
        >
          Payments
        </h2>{' '}
        <h6
          style={{
            fontWeight: 'bold',
            letterSpacing: '2px',
            color: '#a0a0a0',
            paddingBottom: '16px',
          }}
        >
          PROJECTS PRICING
        </h6>
        {isBuyButtonClicked ? (
          <div className="u-flex">
            <table className="dashboard__table">
              <tbody>
                <tr>
                  <td>Selected Item</td>
                  <td>{item}</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>
                    $<span>{price}</span>
                  </td>
                </tr>

                <tr>
                  <td>Number of project(s)</td>
                  <td>
                    <span>
                      <IconButton
                        onClick={() => {
                          if (amount > 1) {
                            setAmount(amount - 1);
                          }
                        }}
                        disabled={amount === 1}
                      >
                        <RemoveCircleOutline />{' '}
                      </IconButton>
                    </span>
                    {price === 135 ? 10 * amount : amount}
                    <IconButton
                      onClick={() => {
                        setAmount(amount + 1);
                      }}
                    >
                      <AddCircleOutline />
                    </IconButton>
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    <span style={{ color: '#aebdaf' }}>$</span>
                    <span>{amount * price}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                width: '90%',
                marginRight: '24px',
                padding: '24px 32px 0px 32px',
              }}
            >
              <PayPalButton
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          currency_code: 'USD',
                          value: amount * price,
                        },
                      },
                    ],
                    // application_context: {
                    //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    // }
                  });
                }}
                onApprove={(data, actions) => {
                  // Capture the funds from the transaction
                  return actions.order.capture().then(async function (details) {
                    // OPTIONAL: Call your server to save the transaction
                    // return fetch('/paypal-transaction-complete', {
                    //   method: 'post',
                    //   body: JSON.stringify({
                    //     orderID: data.orderID,
                    //   }),
                    // });

                    await db
                      .firestore()
                      .collection('payments')
                      .doc(Date.now().toString())
                      .set({
                        paid_by: userInfo.email,
                        payment_time: details.create_time,
                        payer_paypal_name:
                          details.payer.name.given_name +
                          ' ' +
                          details.payer.name.surname,
                        payer_paypal_email: details.payer.email_address,
                        paid_amount:
                          details.purchase_units[0].amount.value +
                          ' ' +
                          details.purchase_units[0].amount.currency_code,
                        trx_id:
                          details.purchase_units[0].payments.captures[0].id,
                        details: JSON.stringify(details),
                        client_timezone: Date().toString(),
                      });

                    await db
                      .firestore()
                      .collection('users')
                      .doc(userInfo.email)
                      .set(
                        {
                          available_projects:
                            price === 135 ? 10 * amount : amount,
                        },
                        { merge: true }
                      );

                    history.push({
                      pathname: '/all-payments',
                      status: 'payment-success',
                    });

                    console.log(details);
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="dashboard__payment">
            {userInfo.user_role !== 'designer' && (
              <>
                <div className="dashboard__paymentPricingCard">
                  <h5>Basic Package</h5>
                  <p style={{ height: '4px' }}></p>
                  <span className="dashboard__paymentPriceAmount">
                    <span style={{ color: '#9da79d', fontSize: '32px' }}>
                      $
                    </span>
                    15
                  </span>
                  <p>per project</p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '32px',
                    }}
                  >
                    <Button
                      className="dashboard__payButton"
                      variant="contained"
                      onClick={() => {
                        setPrice(15);
                        setAmount(1);
                        setItem('Basic Package');
                        setBuyButtonClicked(true);
                      }}
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
                <div className="dashboard__paymentPricingCard">
                  <h5>Bulk Offer</h5>
                  <span className="dashboard__paymentDiscountAmount">
                    <span style={{ textDecoration: 'line-through' }}>$150</span>{' '}
                    Save 10%
                  </span>{' '}
                  <br />
                  <span className="dashboard__paymentPriceAmount">
                    <span style={{ color: '#9da79d', fontSize: '32px' }}>
                      $
                    </span>
                    135
                  </span>
                  <p>for 10 projects</p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '32px',
                    }}
                  >
                    <Button
                      className="dashboard__payButton"
                      variant="contained"
                      onClick={() => {
                        setPrice(135);
                        setAmount(1);
                        setItem('Bulk Offer Package');
                        setBuyButtonClicked(true);
                      }}
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Payments;
