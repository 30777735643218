import { Avatar, Button, CircularProgress, TextField } from '@material-ui/core';
import React, { useState } from 'react';

import Center from '../../widgets/Center';
import DropZoneView from './DropZoneView';
import db from '../../firebase/config';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';

function ProjectUploadView({
  detail,
  setIsNotificationOpen,
  setIsUploadProjectDialogOpen,
}) {
  const [flag, setFlag] = useState('initial');
  const [designLink, setDesignLink] = useState('');
  const [uploadStatus, setUploadStatus] = useState('initial');

  const openPasteLinkContainer = (e) => {
    e.preventDefault();
    setFlag('paste');
  };

  const openUploadContainer = (e) => {
    e.preventDefault();
    setFlag('upload');
  };

  const submitDesignFilesLink = (e) => {
    e.preventDefault();
    setUploadStatus('uploading');

    db.firestore()
      .collection('projects')
      .doc(detail.created)
      .set(
        {
          design_files_link: designLink,
          status: 'Waiting for review',
        },
        { merge: true }
      )
      .then(() => {
        setIsUploadProjectDialogOpen(false);
        setIsNotificationOpen(true);
        setUploadStatus('done');
      });
  };

  return (
    <>
      <>
        <span
          style={{
            color: 'green',
            fontWeight: 'bold',
            display: 'block',
            fontSize: '20px',
          }}
        >
          {detail.project_name}{' '}
          <span
            className="u-badge"
            style={{
              background:
                detail.status === 'Rejected'
                  ? '#ffe6e6'
                  : detail.status === 'Waiting for review'
                  ? '#ffefe6'
                  : detail.status === 'Not assigned'
                  ? '#eaeaea'
                  : '#d9d9ff',
              color:
                detail.status === 'Rejected'
                  ? '#d60f0f'
                  : detail.status === 'Waiting for review'
                  ? '#ad5119'
                  : detail.status === 'Not assigned'
                  ? '#949494'
                  : '#4d4dbf',
            }}
          >
            {detail.status}
          </span>
        </span>
        <span style={{ fontStyle: 'italic' }}>{detail.company_name}</span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            Posted on{' '}
            {moment(detail.posted_on, 'MMM D, yyyy hh:mm:ss a').format(
              'MMM D, yyyy'
            )}
          </span>
          <span>
            {moment(detail.project_due_date, 'MMM D, yyyy').diff(
              moment(),
              'hours'
            ) < 1 ? (
              <>
                <span
                  className="u-badge"
                  style={{
                    background: '#cc0000',
                    color: '#f5f0f0',
                  }}
                >
                  OVERDUE
                </span>{' '}
                <span
                  style={{
                    color: '#cc0000',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {detail.project_due_date}
                </span>
              </>
            ) : (
              <>
                <span
                  className="u-badge"
                  style={{
                    background: '#609426',
                    color: 'white',
                  }}
                >
                  {' '}
                  DUE DATE
                </span>{' '}
                <span
                  style={{
                    color: '#3c8a51',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {detail.project_due_date}
                </span>
              </>
            )}
          </span>
        </div>

        <hr />
      </>
      <div
        style={{
          textAlign: 'center',
          height: 'auto',
          transition: 'height 0.4s',
        }}
      >
        {flag === 'initial' ? (
          <>
            <div style={{ margin: '20px 0px' }}>
              <Button
                className="u-rowButton button__action"
                variant="outlined"
                onClick={openUploadContainer}
              >
                Upload Completed Project files
              </Button>
            </div>

            <div
              style={{
                height: '1px',
                width: '50%',
                backgroundColor: '#f1f1f1',
                margin: '0 auto',
              }}
            >
              <span
                style={{
                  padding: '8px',
                  backgroundColor: 'white',
                  position: 'relative',
                  bottom: '8px',
                  color: 'grey',
                }}
              >
                OR
              </span>
            </div>
            <div style={{ margin: '20px 0px' }}>
              <Button
                className="u-rowButton"
                variant="outlined"
                onClick={openPasteLinkContainer}
              >
                Paste the Google Drive Link that is already uploaded
              </Button>
            </div>
          </>
        ) : flag === 'paste' ? (
          <div className="pasteProject">
            <h5 style={{ fontWeight: 'bold' }}>Google Drive Link</h5>
            <TextField
              fullWidth
              helperText="Must have public access"
              label="Paste Google Drive Link"
              value={designLink}
              onChange={(e) => {
                e.preventDefault();
                setDesignLink(e.target.value);
              }}
            />
            <br />
            <br />
            {uploadStatus === 'uploading' ? (
              <CircularProgress />
            ) : (
              <Button
                className="u-rowButton button__action"
                variant="outlined"
                onClick={submitDesignFilesLink}
              >
                Submit Project
              </Button>
            )}
          </div>
        ) : (
          <div className="uploadProject">
            <DropZoneView
              projectId={detail.created}
              setIsNotificationOpen={setIsNotificationOpen}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ProjectUploadView;
