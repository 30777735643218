import {
  Avatar,
  Button,
  IconButton,
  TextareaAutosize,
} from '@material-ui/core';
import { AttachmentOutlined, CommentOutlined, Send } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { UserContext } from '../App';
import db from '../firebase/config';

function ProjectCommetView({ projectId, designer, company, projectName }) {
  const userInfo = useContext(UserContext);

  const triggerUploadFilesButton = () => {
    console.log('button clicked');
    document.getElementById('fileUploadButton').click();
  };

  const getFile = (e) => {
    var file = e.target.files[0];
    var currentDateString = Date.now().toString();

    db.storage()
      .ref(currentDateString)
      .child(currentDateString + '-' + file.name)
      .put(file)
      .on(
        'state_changed',
        (progress) => {
          console.log('progress');
          console.log(progress);
        },
        (error) => {
          console.log('error');
          console.log(error);
        },
        (complete) => {
          console.log('complete');
          console.log(complete);
          db.storage()
            .ref(currentDateString)
            .child(currentDateString + '-' + file.name)
            .getDownloadURL()
            .then((imageUrl) => {
              db.firestore()
                .collection('projects')
                .doc(projectId)
                .collection('comments')
                .doc(currentDateString)
                .set(
                  {
                    created: currentDateString,
                    commented_by_email: userInfo.email,
                    commented_by: userInfo.name,
                    mediaUrl: imageUrl,
                    avatar_url: userInfo.avatar_url ? userInfo.avatar_url : '',
                  },
                  { merge: true }
                );
            });
        }
      );
  };

  const handlePostingNewComment = async () => {
    console.log(comment);
    var dateString = Date.now().toString();
    await db
      .firestore()
      .collection('projects')
      .doc(projectId)
      .collection('comments')
      .doc(dateString)
      .set(
        {
          created: dateString,
          commented_by_email: userInfo.email,
          commented_by: userInfo.name,
          comment: comment,
          avatar_url: userInfo.avatar_url ? userInfo.avatar_url : '',
        },
        { merge: true }
      )
      .then(() => {
        setComment('');
      });

    await db.firestore().collection('notifications').doc(dateString).set(
      {
        created: dateString,
        designer: designer,
        company: company,
        commentator_email: userInfo.email,
        commentator_name: userInfo.name,
        projectName: projectName,
        projectId: projectId,
      },
      { merge: true }
    );
  };

  const [comment, setComment] = useState('');

  const handleCommentTyping = (e) => {
    setComment(e.target.value);
  };

  return (
    <div>
      <b
        className="u-head-text"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <CommentOutlined style={{ marginRight: '8px' }} /> Comments
      </b>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        <div
          style={{
            display: 'flex',
            margin: '24px 0px 8px 0px',
            alignItems: 'start',
          }}
        >
          {userInfo.avatar_url ? (
            <Avatar
              style={{
                width: '20px',
                height: '20px',
                padding: '2px 16px',
                borderRadius: '50%',
              }}
              src={userInfo.avatar_url}
            />
          ) : (
            <Avatar
              style={{
                width: '20px',
                height: '20px',
                margin: '2px 16px',
                borderRadius: '50%',
              }}
            >
              <b style={{ fontWeight: 'bold' }}>
                {userInfo.email[0].toUpperCase()}
              </b>
            </Avatar>
          )}
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <TextareaAutosize
              value={comment}
              onChange={handleCommentTyping}
              style={{
                padding: '12px',
                width: '100%',
                border: '1px solid #dadada',
                background: 'rgb(255 255 255)',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
                resize: 'none',
              }}
              rowsMin={2}
              rowsMax={6}
              aria-label="Write a comment ..."
              placeholder="Write a comment ..."
            />
            <div style={{ padding: '2px 8px' }}>
              <IconButton
                onClick={handlePostingNewComment}
                disabled={comment.length === 0}
                color="primary"
              >
                <Send />
              </IconButton>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: '52px' }}>
          <Button
            className="attachment__icon"
            startIcon={<AttachmentOutlined />}
            style={{ color: 'var(--brand-color)' }}
            onClick={triggerUploadFilesButton}
          >
            <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
              Add an Attachment
            </span>
          </Button>
          <input id="fileUploadButton" type="file" hidden onChange={getFile} />
        </div>
      </div>
    </div>
  );
}

export default ProjectCommetView;
