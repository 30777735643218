import { IconButton } from '@material-ui/core';
import { ArrowBack, CalendarToday, Email, MailOutline } from '@material-ui/icons';
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import "../../css/MessageBody.css"


function MessageBody() {


    const props = useLocation();
    const history = useHistory();

    return (


        <div className="body">
            <div className="messageBody__toolbar">
                <IconButton className="messageBody__back" onClick={() => history.goBack()}>
                    <ArrowBack />
                </IconButton>
            </div>
            <div className="messageBody__main">
                <h6 className="MuiTypography-root MuiTypography-h6">Inbox</h6>
                <div className="info">
                    <div className="info__field">
                        <Email className="app__icon" />
                        {props.email}
                    </div>
                    <div className="info__field">
                        <CalendarToday className="app__icon" />
                        {props.date}
                    </div>
                </div>

                <hr />
                <div className="message">
                    {props.message}</div>
            </div>

        </div>
    )
}

export default MessageBody
