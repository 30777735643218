import {
  DescriptionOutlined,
  EditOutlined,
  ImageOutlined,
  LinkOutlined,
} from '@material-ui/icons';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Avatar,
  IconButton,
} from '@material-ui/core';

import React, { useContext, useEffect, useRef, useState } from 'react';
import ScrollableGallery from '../../widgets/ScrollableGallery';
import moment from 'moment';
import ProjectCommetView from '../../widgets/ProjectCommetView';
import SingleComment from '../../widgets/SingleComment';
import db from '../../firebase/config';
import { UserContext } from '../../App';
import ProjectDetailsEditView from '../../widgets/ProjectDetailsEditView';
import Linkify from 'react-linkify';

function ProjectDetailsView({ detail, closeDialog }) {
  const [commentList, setCommentList] = useState([]);
  const userInfo = useContext(UserContext);

  useEffect(() => {
    db.firestore()
      .collection('projects')
      .doc(detail.created)
      .collection('comments')
      .onSnapshot((collectionSnapShot) => {
        let loadedList = collectionSnapShot.docs
          .map((doc) => doc.data())
          .reverse();

        setCommentList(loadedList);
      });
  }, [detail.created]);

  const editElementRef = useRef(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  useEffect(() => {
    if (isEditDialogOpen) {
      const { current: descriptionElement } = editElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isEditDialogOpen]);

  const handleEditIconClick = () => {
    openDetailEdit();
  };

  const openDetailEdit = () => {
    setIsEditDialogOpen(true);
  };

  return (
    <>
      <div>
        {/* detail edit dialog */}
        <Dialog
          className="projectDetailEdit__dialog"
          open={isEditDialogOpen}
          onClose={closeDialog}
          scroll="paper"
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers>
            <div
              id="scroll-dialog-detail-description"
              ref={editElementRef}
              tabIndex={-1}
            >
              <ProjectDetailsEditView
                detail={detail}
                closeDialog={closeDialog}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <div>
          <h4
            style={{
              color: '#609426',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                marginRight: '12px',
                borderRadius: '4px',
                padding: '2px 6px',
                backgroundColor: '#609426',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {detail.project_type ? detail.project_type : '3D'}
            </span>
            {detail.project_name}{' '}
            <span
              className="u-badge"
              style={{
                marginLeft: '12px',
                background:
                  detail.status === 'Rejected'
                    ? '#ffe6e6'
                    : detail.status === 'Waiting for review'
                    ? '#ffefe6'
                    : detail.status === 'Not assigned'
                    ? '#eaeaea'
                    : detail.status === 'Approved'
                    ? '#edffd9'
                    : detail.status === 'Finished'
                    ? '#cef9f5'
                    : '#d9d9ff',
                color:
                  detail.status === 'Rejected'
                    ? '#d60f0f'
                    : detail.status === 'Waiting for review'
                    ? '#ad5119'
                    : detail.status === 'Not assigned'
                    ? '#949494'
                    : detail.status === 'Approved'
                    ? '#338036'
                    : detail.status === 'Finished'
                    ? '#287390'
                    : '#4d4dbf',
              }}
            >
              {detail.status}
            </span>
          </h4>
          <span className="u-text" style={{ fontWeight: 'bold' }}>
            {detail.company_name}
          </span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Posted on{' '}
              {moment(detail.posted_on, 'MMM D, yyyy hh:mm:ss a').format(
                'MMM D, yyyy'
              )}
            </span>
            <span>
              {moment(detail.project_due_date, 'MMM D, yyyy').diff(
                moment(),
                'hours'
              ) < 1 ? (
                <>
                  <span
                    className="u-badge"
                    style={{
                      background: '#cc0000',
                      color: '#f5f0f0',
                    }}
                  >
                    OVERDUE
                  </span>{' '}
                  <span
                    style={{
                      color: '#cc0000',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {detail.project_due_date}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="u-badge"
                    style={{
                      background: '#609426',
                      color: 'white',
                    }}
                  >
                    {' '}
                    DUE DATE
                  </span>{' '}
                  <span
                    style={{
                      color: '#3c8a51',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {detail.project_due_date}
                  </span>
                </>
              )}
            </span>
          </div>
          {detail.is_assigned && (
            <div
              style={{
                width: '100%',

                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  padding: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  style={{
                    background: '#609426',
                    width: '28px',
                    height: '28px',
                  }}
                >
                  {detail.assigned_to_name[0]}
                </Avatar>
              </div>
              <div
                className="u-text"
                style={{ padding: '6px', fontWeight: 'bold' }}
              >
                {detail.assigned_to_name}
              </div>
              <span style={{ fontSize: '11px' }}>(Assigned Designer)</span>
            </div>
          )}
          <hr />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <b
            className="u-head-text"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <DescriptionOutlined style={{ marginRight: '8px' }} /> Project
            Description
          </b>
          {(detail.posted_by_email === userInfo.email || userInfo.is_admin) && (
            <IconButton onClick={handleEditIconClick}>
              <EditOutlined />
            </IconButton>
          )}
        </div>
        <div
          style={{
            background: '#fbfbfb',
            padding: '12px',
            borderRadius: '8px',
            margin: '12px 0px 24px',
          }}
        >
          <p style={{ lineHeight: '1.8' }}>
            <Linkify>{detail.project_desc}</Linkify>
          </p>

          <b className="u-text">Location</b>
          <p style={{ paddingTop: '8px' }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                'https://www.google.com/maps/place/' +
                detail.project_map_location
              }
            >
              {detail.project_map_location}
            </a>
          </p>
          {detail.project_files_link && detail.project_files_link.length > 0 && (
            <>
              <b className="u-text">File Link</b>
              <p style={{ paddingTop: '8px' }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={detail.project_files_link}
                >
                  {detail.project_files_link}
                </a>
              </p>
            </>
          )}
          {detail.project_files && detail.project_files.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <b className="u-text">Project Files</b>
              </div>
              <ScrollableGallery filesList={detail.project_files} />
            </>
          )}
        </div>

        {detail.designer_submitted_files &&
        detail.designer_submitted_files.length > 0 ? (
          <>
            <div
              className="u-head-text"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <ImageOutlined style={{ marginRight: '8px' }} />
              Submitted Files by Designer
              <span
                className="u-badge"
                style={{
                  marginLeft: '12px',
                  background:
                    detail.status === 'Rejected'
                      ? '#ffe6e6'
                      : detail.status === 'Waiting for review'
                      ? '#ffefe6'
                      : detail.status === 'Not assigned'
                      ? '#eaeaea'
                      : detail.status === 'Approved'
                      ? '#edffd9'
                      : detail.status === 'Finished'
                      ? '#cef9f5'
                      : '#d9d9ff',
                  color:
                    detail.status === 'Rejected'
                      ? '#d60f0f'
                      : detail.status === 'Waiting for review'
                      ? '#ad5119'
                      : detail.status === 'Not assigned'
                      ? '#949494'
                      : detail.status === 'Approved'
                      ? '#338036'
                      : detail.status === 'Finished'
                      ? '#287390'
                      : '#4d4dbf',
                }}
              >
                {detail.status}
              </span>
            </div>{' '}
            <ScrollableGallery filesList={detail.designer_submitted_files} />
          </>
        ) : (
          <></>
        )}

        {detail.design_files_link && detail.design_files_link.length > 0 ? (
          <>
            <div
              className="u-head-text"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              <LinkOutlined style={{ marginRight: '8px' }} />
              Submitted Link by Designer
              <span
                className="u-badge"
                style={{
                  marginLeft: '12px',
                  background:
                    detail.status === 'Rejected'
                      ? '#ffe6e6'
                      : detail.status === 'Waiting for review'
                      ? '#ffefe6'
                      : detail.status === 'Not assigned'
                      ? '#eaeaea'
                      : detail.status === 'Approved'
                      ? '#edffd9'
                      : detail.status === 'Finished'
                      ? '#cef9f5'
                      : '#d9d9ff',
                  color:
                    detail.status === 'Rejected'
                      ? '#d60f0f'
                      : detail.status === 'Waiting for review'
                      ? '#ad5119'
                      : detail.status === 'Not assigned'
                      ? '#949494'
                      : detail.status === 'Approved'
                      ? '#338036'
                      : detail.status === 'Finished'
                      ? '#287390'
                      : '#4d4dbf',
                }}
              >
                {detail.status}
              </span>
            </div>
            <div style={{ marginBottom: '24px' }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={detail.design_files_link}
              >
                {detail.design_files_link}
              </a>
            </div>
          </>
        ) : (
          <></>
        )}
        <hr />
        <ProjectCommetView
          projectId={detail.created}
          designer={detail.assigned_to_name ? detail.assigned_to_name : ''}
          projectName={detail.project_name}
          company={detail.company_name}
        />

        {commentList.map((comment) =>
          comment.mediaUrl ? (
            <SingleComment
              key={comment.created}
              commentator={comment.commented_by}
              mediaUrl={comment.mediaUrl}
              avatarUrl={comment.avatar_url}
              timestamp={comment.created}
            />
          ) : (
            <SingleComment
              key={comment.created}
              commentator={comment.commented_by}
              comment={comment.comment}
              avatarUrl={comment.avatar_url}
              timestamp={comment.created}
            />
          )
        )}
      </div>
    </>
  );
}

export default ProjectDetailsView;
