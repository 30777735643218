import { Avatar, Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import Linkify from 'react-linkify';

function SingleComment({
  commentator,
  comment,
  timestamp,
  avatarUrl,
  mediaUrl,
}) {
  return (
    <div style={{ display: 'flex', marginBottom: '12px' }}>
      {avatarUrl !== '' ? (
        <Avatar
          style={{
            width: '20px',
            height: '20px',
            margin: '8px 16px',
            borderRadius: '50%',
          }}
          src={avatarUrl}
        />
      ) : (
        <Avatar
          style={{
            width: '20px',
            height: '20px',
            margin: '8px 16px',
            borderRadius: '50%',
          }}
        >
          <b style={{ fontWeight: 'bold' }}>{commentator[0].toUpperCase()}</b>
        </Avatar>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 12px',
            background: '#f9f9f9',
            borderTopRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <span
            style={{ fontWeight: 'bold', fontSize: '14px', color: '#609426' }}
          >
            {commentator}
          </span>
          <span style={{ marginTop: '4px' }}>
            {mediaUrl ? (
              <img
                src={mediaUrl}
                style={{ maxWidth: '360px', padding: '16px 8px' }}
                alt=""
              />
            ) : (
              <Linkify>{comment}</Linkify>
            )}
          </span>
        </div>
        <div>
          {/* <span style={{ marginRight: '16px' }}>Reply</span> */}
          <span style={{ color: '#777777', fontSize: 10, padding: '14px 8px' }}>
            {moment().diff(moment.unix(timestamp / 1000), 'days') <= 3 ? (
              <Tooltip
                title={moment
                  .unix(timestamp / 1000)
                  .format('MMM D, yyyy hh:mm a')}
                arrow
              >
                <span>{moment.unix(timestamp / 1000).fromNow()} </span>
              </Tooltip>
            ) : (
              <span>
                {moment.unix(timestamp / 1000).format('MMM D, yyyy hh:mm a')}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SingleComment;
