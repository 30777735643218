import '../../css/SignUp.css';
import '../../css/Login.css';

import {
  AccountCircle,
  AccountCircleOutlined,
  LoopOutlined,
} from '@material-ui/icons';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Link, Redirect, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import appLogo from '../../assets/logo.png';
import db from '../../firebase/config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },

  logo: {
    borderRadius: '30px',
  },

  image: {
    backgroundImage:
      'url(https://vistaconcepts.co/wp-content/uploads/2021/04/work-15.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [didClickForgetPassword, setDidClickForgetPassword] = useState(false);
  const [isSignUpChoiceDone, setSignUpChoiceDone] = useState(false);
  const [userRole, setUserRole] = useState('none');
  const [signInStepCount, setSignInStepCount] = useState(0);
  const [newUserInfo, setNewUserInfo] = useState({});
  const [step0Clicked, setStep0Clicked] = useState(false);
  const [step1Clicked, setStep1Clicked] = useState(false);
  const [step2Clicked, setStep2Clicked] = useState(false);
  const [isSalesPerson, setIsSalesPerson] = useState(false);

  const signUpNewUser = async (e) => {
    e.preventDefault();

    setErrorMessage('');

    console.log('trying to register the new user');
    setStep2Clicked(true);
    if (
      newUserInfo.password &&
      newUserInfo.repeatedPassword &&
      newUserInfo.password === newUserInfo.repeatedPassword
    ) {
      setIsLoading(true);
      await db
        .auth()
        .createUserWithEmailAndPassword(newUserInfo.email, newUserInfo.password)
        .then(async () => {
          await db.firestore().collection('users').doc(newUserInfo.email).set({
            company: newUserInfo.company,
            address: newUserInfo.address,
            zip: newUserInfo.zip,
            city: newUserInfo.city,
            state: newUserInfo.state,
            name: newUserInfo.name,
            phone: newUserInfo.phone,
            email: newUserInfo.email,
            user_role: userRole,
            is_admin: false,
          });
        })
        .catch((e) => setErrorMessage(e.message));
      setIsLoading(false);
    }
  };

  const signUpNewDesigner = async (e) => {
    e.preventDefault();

    setErrorMessage('');

    console.log('trying to register the new designer');
    setStep2Clicked(true);
    if (
      newUserInfo.password &&
      newUserInfo.repeatedPassword &&
      newUserInfo.password === newUserInfo.repeatedPassword
    ) {
      setIsLoading(true);
      await db
        .auth()
        .createUserWithEmailAndPassword(newUserInfo.email, newUserInfo.password)
        .then(async () => {
          await db.firestore().collection('users').doc(newUserInfo.email).set({
            name: newUserInfo.name,
            phone: newUserInfo.phone,
            email: newUserInfo.email,
            user_role: userRole,
            company: '',
            address: '',
            zip: '',
            city: '',
            state: '',
            is_admin: false,
          });
        })
        .catch((e) => setErrorMessage(e.message));
      setIsLoading(false);
    }
  };

  return (
    <Grid container component="main" style={{ paddingTop: '64px' }}>
      <div className="signup__header">
        <div className="signup__headerLeft">
          <Link to="/">
            <img src={appLogo} className="header__logo" />
          </Link>
        </div>
        <div className="signup__headerRight">
          {isSignUpChoiceDone ? (
            <>
              <div className="signup__selectedChoiceContainer">
                <span style={{ fontSize: '10px', textAlign: 'right' }}>
                  Signing up as
                </span>
                {userRole === 'client' ? (
                  <span
                    style={{ color: 'var(--brand-color)', textAlign: 'right' }}
                  >
                    {' '}
                    Landscape Company Client / Sales Person
                  </span>
                ) : (
                  <span style={{ color: 'var(--brand-color)' }}>
                    {' '}
                    2D / 3D Designer
                  </span>
                )}
              </div>
              <IconButton
                className="signup__selectedChoiceChangeButton"
                style={{ outline: 'none', marginLeft: '4px' }}
                onClick={() => {
                  let currentRole;
                  if (userRole === 'client') {
                    currentRole = 'designer';
                    setSignInStepCount(1);
                  } else if (userRole === 'designer') {
                    currentRole = 'client';
                    setSignInStepCount(0);
                  }
                  setUserRole(currentRole);
                }}
              >
                <LoopOutlined />
              </IconButton>
            </>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <CssBaseline />

      {!isSignUpChoiceDone ? (
        <>
          <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
            <div className="signup__container">
              <h2
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: 'grey',
                  fontSize: 22,
                }}
              >
                You are a
              </h2>

              <div className="signup__choiceList u-animate-fade u-animate-slideDown">
                <SignUpChoiceItem
                  title="Landscape Company Client or Sales Person"
                  userRole="client"
                  desc="Post designs and manage all jobs"
                />

                <SignUpChoiceItem
                  title="2D / 3D Designer"
                  userRole="designer"
                  desc="Get jobs and submit your design"
                />
              </div>

              <div style={{ height: '100px' }}></div>
            </div>
          </div>
        </>
      ) : (
        <>
          {signInStepCount === 0 ? (
            <div className="centerItem">
              <div className="u-animate-slideDown signup__card">
                <div style={{ padding: '0 12px' }}>
                  {userRole === 'client' && (
                    <p style={{ textAlign: 'center' }}>Step 1 of 3</p>
                  )}
                  <h2
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: '#474747',
                      textAlign: 'center',
                    }}
                  >
                    Details of{' '}
                    <span
                      style={{
                        color: 'var(--brand-color, )',
                        fontSize: 20,
                        fontWeight: 'bold',
                      }}
                    >
                      The Company
                    </span>
                  </h2>
                  {isSalesPerson ? (
                    <TextField
                      required
                      fullWidth
                      label="Name of the company"
                      autoComplete="false"
                      value={newUserInfo.company}
                      error={step0Clicked ? !newUserInfo.company : false}
                      helperText={
                        step0Clicked
                          ? !newUserInfo.company
                            ? 'Invalid'
                            : ''
                          : ''
                      }
                      onChange={(event) =>
                        setNewUserInfo({
                          ...newUserInfo,
                          company: event.target.value,
                        })
                      }
                      id="company"
                    />
                  ) : (
                    <form className={classes.form} noValidate>
                      <TextField
                        required
                        fullWidth
                        label="Name of the company"
                        autoComplete="false"
                        value={newUserInfo.company}
                        error={step0Clicked ? !newUserInfo.company : false}
                        helperText={
                          step0Clicked
                            ? !newUserInfo.company
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            company: event.target.value,
                          })
                        }
                        id="company"
                      />
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Address"
                        autoComplete="false"
                        value={newUserInfo.address}
                        error={step0Clicked ? !newUserInfo.address : false}
                        helperText={
                          step0Clicked
                            ? !newUserInfo.address
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            address: event.target.value,
                          })
                        }
                        id="address"
                      />
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="ZIP"
                        autoComplete="false"
                        value={newUserInfo.zip}
                        error={step0Clicked ? !newUserInfo.zip : false}
                        helperText={
                          step0Clicked
                            ? !newUserInfo.zip
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            zip: event.target.value,
                          })
                        }
                        id="zip"
                      />
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="City"
                        autoComplete="false"
                        value={newUserInfo.city}
                        error={step0Clicked ? !newUserInfo.city : false}
                        helperText={
                          step0Clicked
                            ? !newUserInfo.city
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            city: event.target.value,
                          })
                        }
                        id="city"
                      />

                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="State"
                        autoComplete="false"
                        value={newUserInfo.state}
                        error={step0Clicked ? !newUserInfo.state : false}
                        helperText={
                          step0Clicked
                            ? !newUserInfo.state
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            state: event.target.value,
                          })
                        }
                        id="state"
                      />

                      <div className="height-12" />
                      <div className="height-12" />
                      <div className="button-wraper">
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={(e) => {
                            e.preventDefault();
                            setStep0Clicked(true);
                            if (
                              newUserInfo.company &&
                              newUserInfo.address &&
                              newUserInfo.zip &&
                              newUserInfo.city &&
                              newUserInfo.state
                            )
                              setSignInStepCount(1);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                      {/* <Box mt={2}>
              <Copyright />
            </Box> */}
                    </form>
                  )}
                </div>
              </div>
            </div>
          ) : signInStepCount == 1 ? (
            <div className="u-animate-slideLeft centerItem">
              <div className="signup__card">
                <div style={{ padding: '0 12px' }}>
                  {userRole === 'client' && (
                    <p style={{ textAlign: 'center' }}>Step 2 of 3</p>
                  )}
                  {userRole === 'designer' && (
                    <p style={{ textAlign: 'center' }}>Step 1 of 2</p>
                  )}
                  <h2
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: '#474747',
                      textAlign: 'center',
                    }}
                  >
                    Your{' '}
                    <span
                      style={{
                        color: 'var(--brand-color, )',
                        fontSize: 20,
                        fontWeight: 'bold',
                      }}
                    >
                      Information
                    </span>
                  </h2>

                  <form className={classes.form} noValidate style={{}}>
                    <TextField
                      required
                      fullWidth
                      label="Your Name"
                      autoComplete="false"
                      value={newUserInfo.name}
                      error={step1Clicked ? !newUserInfo.name : false}
                      helperText={
                        step1Clicked ? (!newUserInfo.name ? 'Invalid' : '') : ''
                      }
                      onChange={(event) =>
                        setNewUserInfo({
                          ...newUserInfo,
                          name: event.target.value,
                        })
                      }
                      id="name"
                    />
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Phone"
                      autoComplete="false"
                      value={newUserInfo.phone}
                      error={step1Clicked ? !newUserInfo.phone : false}
                      helperText={
                        step1Clicked
                          ? !newUserInfo.phone
                            ? 'Invalid'
                            : ''
                          : ''
                      }
                      onChange={(event) =>
                        setNewUserInfo({
                          ...newUserInfo,
                          phone: event.target.value,
                        })
                      }
                      id="phone"
                    />
                    <div className="height-12"></div>
                    <div className="height-12"></div>
                    <div className="button-wraper">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={(e) => {
                          e.preventDefault();
                          setStep1Clicked(true);
                          if (newUserInfo.name && newUserInfo.phone)
                            setSignInStepCount(2);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                    {/* <Box mt={2}>
              <Copyright />
            </Box> */}
                  </form>
                </div>
              </div>
              {/* <div style={{ height: '100px' }}></div> */}
            </div>
          ) : (
            signInStepCount == 2 && (
              <>
                <div class="centerItem">
                  <div className="u-animate-slideLeft signup__card">
                    {userRole === 'client' && (
                      <p style={{ textAlign: 'center' }}>Step 3 of 3</p>
                    )}
                    {userRole === 'designer' && (
                      <p style={{ textAlign: 'center' }}>Step 2 of 2</p>
                    )}
                    <h2
                      style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: '#474747',
                        textAlign: 'center',
                      }}
                    >
                      Sign up to{' '}
                      <span
                        style={{
                          color: 'var(--brand-color, )',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Vista Concepts
                      </span>
                    </h2>

                    <form className={classes.form} noValidate>
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Email Address"
                        autoComplete="false"
                        value={newUserInfo.email}
                        error={step2Clicked ? !newUserInfo.email : false}
                        helperText={
                          step2Clicked
                            ? !newUserInfo.email
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            email: event.target.value,
                          })
                        }
                        id="email"
                      />

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        autoComplete="false"
                        type="password"
                        value={newUserInfo.password}
                        error={step2Clicked ? !newUserInfo.password : false}
                        helperText={
                          step2Clicked
                            ? !newUserInfo.password
                              ? 'Invalid'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            password: event.target.value,
                          })
                        }
                        id="password"
                      />

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Repeat Password"
                        autoComplete="false"
                        type="password"
                        value={newUserInfo.repeatedPassword}
                        error={
                          step2Clicked
                            ? newUserInfo.password !==
                              newUserInfo.repeatedPassword
                              ? true
                              : !newUserInfo.repeatedPassword
                            : false
                        }
                        helperText={
                          step2Clicked
                            ? !newUserInfo.repeatedPassword
                              ? 'Invalid'
                              : newUserInfo.password !==
                                newUserInfo.repeatedPassword
                              ? 'Not Matched'
                              : ''
                            : ''
                        }
                        onChange={(event) =>
                          setNewUserInfo({
                            ...newUserInfo,
                            repeatedPassword: event.target.value,
                          })
                        }
                        id="repeatedPassword"
                      />

                      <div
                        className="error-message"
                        style={{
                          transition: '0.5s',
                          marginTop: didClickForgetPassword ? '-64px' : '0',
                        }}
                      >
                        {errorMessage}
                      </div>
                      <div className="height-12"></div>
                      <div className="button-wraper">
                        {isLoading ? (
                          <CircularProgress className={classes.submit} />
                        ) : userRole === 'client' ? (
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={signUpNewUser}
                          >
                            Sign Up
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={signUpNewDesigner}
                          >
                            Sign Up as Designer
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                  {/* <div style={{ height: '0px' }}></div> */}
                </div>
              </>
            )
          )}
        </>
      )}
    </Grid>
  );
  function SignUpChoiceItem({ title, desc, userRole }) {
    return (
      <div
        className="signup__choiceItem"
        onClick={() => {
          setSignUpChoiceDone(true);
          setUserRole(userRole);
          if (userRole === 'client') {
            setSignInStepCount(0);
          } else {
            setSignInStepCount(1);
          }
        }}
      >
        <h3 style={{ lineHeight: '1.5' }}>{title}</h3>
        <p>{desc}</p>
      </div>
    );
  }
}
