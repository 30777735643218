import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import tableIcons from "../../table/Table-init";

import db from "../../firebase/config";
import { FormControl, IconButton, InputLabel, Select } from "@material-ui/core";

import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import MessageBody from "./MessageBody";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setfilteredMessages] = useState([]);
  const [driversNameList, setDriverNameList] = useState([]);
  const [selectedDriverName, setSelectedDriverName] = useState("");







  useEffect(() => {
    db.firestore()
      .collection("messages")
      // .where("is_closed", "==", true)
      //.orderBy("created", "desc")
      .onSnapshot(function (querySnapshot) {
        let loadedList = querySnapshot.docs.map((item) => item.data());
        loadedList.sort((a, b) => b.created - a.created)
        let loadedDriverNameList = [
          ...new Set(querySnapshot.docs.map((item) => item.data().driverName)),
        ];
        setDriverNameList(loadedDriverNameList);
        setMessages(loadedList);
        setfilteredMessages(loadedList);
      });


  }, []);

  const history = useHistory();
  return (
    <>
      <div className="body">





        <MaterialTable
          title={`Messages  (${filteredMessages.length})`}
          className="body__table"
          icons={tableIcons}
          data={filteredMessages}
          onRowClick={(event, rowData) =>
            history.push({
              pathname: `/messages/read`,
              message: rowData.message,
              date: rowData.created_value,
              email: rowData.email,
            })}
          columns={[


            { title: "Email", field: "email" },
            {
              title: "Submitted",
              field: "created_value",
              //   type: "datetime",
              //   render: (rowData) => convertDate(rowData.created.seconds),
            },
            { title: "Message", field: "message", hidden: true },


          ]}



        // isLoading="true"
        />


      </div>
    </>
  );
};

export default Messages;
