import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Search } from '@material-ui/icons';

import ListTile from '../../widgets/ListTile.js';
import db from '../../firebase/config.js';

function DesignerList({
  currentProject,
  setOpenAssignView,
  setIsNotificationOpen,
}) {
  const [designerList, setDesignerList] = useState([]);
  const [designerFilterList, setDesignerFilterList] = useState([]);

  const handleOnClick = (email, name) => {
    setOpenAssignView(false);
    console.log(currentProject.created);
    db.firestore().collection('projects').doc(currentProject.created).set(
      {
        is_assigned: true,
        assigned_to_email: email,
        assigned_to_name: name,
        status: 'Work in progress',
      },
      { merge: true }
    );
    setIsNotificationOpen(true);
  };

  useEffect(() => {
    db.firestore()
      .collection('users')
      .where('user_role', '==', 'designer')
      .onSnapshot(function (querySnapshot) {
        let loadedList = querySnapshot.docs.map((item) => item.data());
        setDesignerList(loadedList);
        setDesignerFilterList(loadedList);
      });
  }, []);

  const [searchValue, setSearchValue] = useState('');

  const setSearchValueHandler = (value) => {
    setSearchValue(value);
    if (value !== null || value.trim() !== '')
      setDesignerFilterList(
        designerList.filter((item) =>
          JSON.stringify(item.name.toLowerCase()).includes(value.toLowerCase())
        )
      );
  };

  return designerList.length !== 0 ? (
    <>
      <TextField
        variant="outlined"
        style={{ marginBottom: '24px' }}
        fullWidth
        type="search"
        placeholder="Search"
        value={searchValue}
        onChange={(event) => setSearchValueHandler(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {designerFilterList.map((designer) => (
        <ListTile
          className="u-animate-fade"
          key={designer.email}
          data={designer}
          onClick={() => handleOnClick(designer.email, designer.name)}
        />
      ))}{' '}
      {designerFilterList.length === 0 && (
        <>
          <div
            className="u-animate-fade"
            style={{
              height: '120px',
              width: '100%',
              backgroundColor: '#fafafa',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: '0.4s',
            }}
          >
            No designer matched!
          </div>
        </>
      )}
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
}

export default DesignerList;
