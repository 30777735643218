import '../../App.css';
import '../../css/Body.css';

import {
  Block,
  Check,
  InfoOutlined,
  PersonAdd,
  PersonAddDisabled,
} from '@material-ui/icons';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AccessOnly from '../../widgets/AccessOnly';
import MaterialTable from 'material-table';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import tableIcons from '../../table/Table-init';

const Designers = () => {
  const [users, setusers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    db.firestore()
      .collection('users')
      .where('user_role', '==', 'designer')
      .onSnapshot(function (querySnapshot) {
        let loadedList = querySnapshot.docs.map((item) => item.data());
        setusers(loadedList);
        setIsLoading(false);
      });
  }, []);

  return (
    <AccessOnly userType="admin">
      <div className="body">
        <MaterialTable
          isLoading={isLoading}
          title={`Designers  (${users.length})`}
          className="Users__table"
          icons={tableIcons}
          data={users}
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            { title: 'Email', field: 'email' },
            {
              title: 'Phone',
              field: 'phone',
              render: (rowData) => {
                let phone = `tel:${rowData.phone}`;
                return <a href={phone}>{rowData.phone}</a>;
              },
            },

            // {
            //   title: 'Company',
            //   field: 'company',
            //   render: (rowData) => {
            //     return rowData.company !== '' ? (
            //       <div style={{ display: 'flex', alignItems: 'center' }}>
            //         <span style={{ paddingRight: '8px' }}>
            //           {' '}
            //           {rowData.company}
            //         </span>
            //         <HtmlTooltip
            //           title={
            //             <>
            //               <Typography color="inherit">
            //                 {rowData.address}
            //                 {', '}
            //                 {rowData.city}
            //                 {', '}
            //                 {rowData.state}
            //                 {', '}
            //                 {rowData.zip}{' '}
            //               </Typography>
            //             </>
            //           }
            //         >
            //           <InfoOutlined fontSize="small" />
            //         </HtmlTooltip>
            //       </div>
            //     ) : (
            //       <p></p>
            //     );
            //   },
            //   // lookup: {
            //   //   true: <div className="u-badge badge__approved">Approved</div>,
            //   //   false: (
            //   //     <div className="u-badge badge__not_approved">Pending</div>
            //   //   ),
            //   //   '': <div className="u-badge badge__unknown">Unknown</div>,
            //   // },
            // },

            {
              title: 'User Role',
              field: 'user_role',
              render: (rowData) =>
                rowData.is_admin ? (
                  <span className="u-badge badge__admin">Admin</span>
                ) : rowData.user_role === 'admin' ? (
                  <span className="u-badge badge__nonAdmin">N/A</span>
                ) : rowData.user_role === 'client' ? (
                  <span className="u-badge badge__client">Client</span>
                ) : rowData.user_role === 'designer' ? (
                  <span className="u-badge badge__designer">Designer</span>
                ) : (
                  <span></span>
                ),
            },
          ]}
          // actions={[
          //   (rowData) => ({
          //     hidden: rowData.is_admin === true,
          //     icon: () => (
          //       <>
          //         <PersonAdd style={{ color: 'green' }} />
          //       </>
          //     ),
          //     tooltip: 'Make Admin',
          //     onClick: (event, rowData) => {
          //       setIsLoading(true);
          //       makeAdmin(rowData.email).then((_) => setIsLoading(false));
          //     },
          //   }),
          //   (rowData) => ({
          //     hidden: rowData.is_admin !== true,
          //     icon: () => <PersonAddDisabled style={{ color: 'red' }} />,
          //     tooltip: 'Remove as Admin',
          //     onClick: (event, rowData) => {
          //       setIsLoading(true);
          //       removeAdmin(rowData.email).then((_) => setIsLoading(false));
          //     },
          //   }),
          // ]}

          options={{
            actionsColumnIndex: -1,
            pageSizeOptions: [20, 30, 40, 80, 100],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
        />
      </div>
    </AccessOnly>
  );
};

export default Designers;
