import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';

import MaterialTable from 'material-table';
import NoAccess from '../../widgets/NoAccess';
import NotificationShow from '../../widgets/NotificationShow';

import { ProjectsListContext, UserContext } from '../../App';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import moment from 'moment';
import tableIcons from '../../table/Table-init';
import { useHistory, useLocation } from 'react-router-dom';
import PaymentDetailsView from '../../widgets/PaymentDetailsView';

const AllPayments = () => {
  const props = useLocation();
  const args = props.status === 'payment-success' ? true : false;

  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValueList, setSearchValueList] = useState([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');
  const [newProjectInfo, setNewProjectInfo] = useState({});

  const userInfo = useContext(UserContext);
  const projectsListInfo = useContext(ProjectsListContext);

  const OptionList = (props) => {
    return props.optionList.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  };

  const handleChange = (e) => {
    console.log('handleChange -> handleChange called');
    setSelectedSearchValue(e.target.value);
    let filteredLogList = projectList.filter((list) =>
      e.target.value === '' ? list : list.paid_by === e.target.value
    );

    setFilteredList(filteredLogList);
    console.log(e.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    db.firestore()
      .collection('payments')
      .onSnapshot(function (querySnapshot) {
        let loadedList = querySnapshot.docs
          .map((item) => item.data())
          .reverse();
        let loadedEmailList = [
          ...new Set(loadedList.map((item) => item.paid_by)),
        ];

        setSearchValueList(loadedEmailList);
        setProjectList(loadedList);
        setFilteredList(loadedList);
        setIsLoading(false);
      });
  }, []);

  const [open, setOpen] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);

  const [scroll, setScroll] = useState('paper');

  const [currentRowData, setCurrentRowData] = useState({});

  const openProjectDetailModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setOpenDetailView(true);
    setScroll(scrollType);
  };

  const openNewProjectForm = (scrollType) => () => {
    setNewProjectInfo({});

    setOpen(true);
    setScroll(scrollType);
  };

  const openAssignViewModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseView = () => {
    setOpenDetailView(false);
  };

  const descriptionElementRef = useRef(null);
  const descriptionElementRefDetail = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(args);

  return (
    <NoAccess userType="designer">
      <div className="body">
        <NotificationShow
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
        />

        <div className="body__toolbar">
          <FormControl className="log__selectRoot">
            {userInfo.user_role !== 'Client' && (
              <>
                <InputLabel htmlFor="age-native-simple">
                  Filter by Email{' '}
                </InputLabel>
                <Select
                  native
                  value={selectedSearchValue}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <OptionList optionList={searchValueList} />
                </Select>
              </>
            )}
          </FormControl>

          <Button
            variant="contained"
            className="newProjects__postButton"
            onClick={() => {
              history.push('/payments');
            }}
          >
            Buy projects
          </Button>
        </div>

        <Dialog
          className="projectDetail__dialog"
          open={openDetailView}
          onClose={handleCloseView}
          scroll={scroll}
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-detail-description"
              ref={descriptionElementRefDetail}
              tabIndex={-1}
            >
              <PaymentDetailsView detail={currentRowData} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          title={`All Payments (${filteredList.length})`}
          className="body__table"
          icons={tableIcons}
          data={filteredList}
          columns={[
            {
              title: 'Paid by',
              field: 'paid_by',
              render: (rowData) => (
                <>
                  <div
                    onClick={openProjectDetailModal('paper', rowData)}
                    className="rowProject__Title"
                  >
                    <span>{rowData.paid_by}</span>
                  </div>
                </>
              ),
            },

            {
              title: 'Paid amount',
              field: 'paid_amount',
            },

            {
              title: 'Paypal account',
              field: 'payer_paypal_email',
            },

            {
              title: 'Paid at',
              field: 'payment_time',

              render: (rowData) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      backgroundColor:
                        moment().diff(moment(rowData.payment_time), 'hours') <
                        24
                          ? '#26cc26'
                          : 'grey',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />

                  <span>
                    {moment().diff(moment(rowData.payment_time), 'minutes') < 1
                      ? 'Just now'
                      : moment().diff(moment(rowData.payment_time), 'minutes') <
                        60
                      ? moment().diff(moment(rowData.payment_time), 'minutes') +
                        ' minutes ago'
                      : moment().diff(moment(rowData.payment_time), 'hours') <
                        24
                      ? moment().diff(moment(rowData.payment_time), 'hours') +
                        ' hours ago'
                      : moment(rowData.payment_time).format('MMM D, yyyy')}
                  </span>
                </div>
              ),
            },
          ]}
          options={{
            exportButton: true,
            pageSizeOptions: [20, 30, 40, 60],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
          isLoading={isLoading}
        />
      </div>
    </NoAccess>
  );
};

export default AllPayments;
