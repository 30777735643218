import React, { createRef, useState } from 'react';

import { Button } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import db from '../../firebase/config';

function DropZoneView({ projectId, setIsNotificationOpen }) {
  const [uploadStatus, setUploadStatus] = useState('initial');
  const [uploadCount, setUploadCount] = useState(0);
  let imageList = [];

  const dropzoneRef = createRef();
  const openDialog = () => {
    console.log('opening file dialog');
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  let count = 0;

  const upload = async (file, total) => {
    let dateString = Date.now();
    db.storage()
      .ref(projectId)
      .child(dateString + '-' + file.name)
      .put(file)
      .on(
        'state_changed',
        (progress) => {
          console.log('progress');
          console.log(progress);
        },
        (error) => {
          console.log('error');
          console.log(error);
        },
        (complete) => {
          console.log('complete');

          db.storage()
            .ref(projectId)
            .child(dateString + '-' + file.name)
            .getDownloadURL()
            .then((imageUrl) => {
              imageList = [...imageList, imageUrl];

              count++;

              setUploadCount(count);
              if (total === count) {
                console.log('upload count must be same now');
                db.firestore().collection('projects').doc(projectId).set(
                  {
                    designer_submitted_files: imageList,
                    status: 'Waiting for review',
                  },
                  { merge: true }
                );

                setTimeout(() => {
                  setUploadStatus('done');
                  setIsNotificationOpen(true);
                }, 800);
              }
            });
        }
      );
  };

  const getFileExtension = (file) =>
    file.type.slice(file.type.lastIndexOf('/') + 1, file.type.length);

  return (
    <>
      <Dropzone ref={dropzoneRef} noClick noKeyboard>
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          return (
            <>
              {acceptedFiles.length === 0 && (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    padding: '80px 0',
                    backgroundColor: '#fcfcfc',
                    border: '1px solid #e0dbdb',
                    borderRadius: '12px',
                  }}
                >
                  <input {...getInputProps()} />
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: '#cecece',
                      fontSize: '16px',
                    }}
                  >
                    Drag & drop some files here
                  </p>
                  <Button
                    variant="outlined"
                    className="u-rowButton"
                    onClick={openDialog}
                  >
                    Open File Dialog
                  </Button>
                </div>
              )}
              {acceptedFiles.length > 0 && (
                <div style={{ padding: '24px 4px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                      }}
                    >
                      {acceptedFiles.length} files{' '}
                      {uploadStatus === 'done' ? <>uploaded</> : <>selected</>}
                    </p>
                  </div>

                  <ul
                    style={{
                      display: 'flex',
                      listStyleType: 'none',
                      paddingLeft: '0',
                      overflowX: 'auto',
                    }}
                  >
                    {acceptedFiles.map((file) => (
                      <li key={file.path}>
                        {file.type === 'image/jpeg' ||
                        file.type === 'image/png' ||
                        file.type === 'image/gif' ? (
                          <img
                            src={URL.createObjectURL(file)}
                            height={64}
                            style={{
                              margin: '8px',
                              borderRadius: '8px',
                              border: '2px solid #b6bbb6',
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              height: '64px',
                              width: '52px',
                              backgroundColor: '#f9f9f9',
                              borderRadius: '8px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '8px',
                              border: '2px solid #b6bbb6',
                            }}
                          >
                            <span className="fileExt">
                              {getFileExtension(file)}
                            </span>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  {uploadStatus === 'done' ? (
                    <p style={{ color: '#609426', fontWeight: 'bold' }}>
                      Your files are successfully uploaded!
                    </p>
                  ) : uploadStatus === 'uploading' ? (
                    <>
                      <div
                        style={{
                          width: '100%',
                          height: '8px',
                          backgroundColor: '#bbbbbb',
                          borderRadius: '4px',
                          marginBottom: '12px',
                        }}
                      >
                        <div
                          style={{
                            transition: '1.0s',
                            width:
                              (uploadCount * 100) / acceptedFiles.length + '%',
                            height: '8px',
                            backgroundColor: '#609426',
                            borderRadius: '4px',
                          }}
                        ></div>
                      </div>
                      <p>Uploading</p>
                    </>
                  ) : (
                    <Button
                      className="u-rowButton button__action"
                      variant="outlined"
                      onClick={(e) => {
                        setUploadStatus('uploading');
                        e.preventDefault();
                        acceptedFiles.map(async (file) => {
                          await upload(file, acceptedFiles.length);
                        });
                      }}
                    >
                      Upload Completed Project Files
                    </Button>
                  )}
                </div>
              )}
            </>
          );
        }}
      </Dropzone>
    </>
  );
}

export default DropZoneView;
