import { Button, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import UploaderView from '../../widgets/UploaderView';
import moment from 'moment';

function FormNewProjects({
  newProjectInfo,
  setNewProjectInfo,
  setSelectedFiles,
  uploadCount,
  uploadStatus,
}) {
  const [selectedDate, setSelectedDate] = useState(
    moment().format('MMM D, yyyy')
  );

  useEffect(() => {
    setNewProjectInfo({
      ...newProjectInfo,
      project_due_date: moment().format('MMM D, yyyy'),
    });
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setNewProjectInfo({
      ...newProjectInfo,
      project_due_date: moment(date).format('MMM D, yyyy'),
    });
  };

  const [isAttachmentTypeUpload, setIsAttachmentTypeUpload] =
    useState('upload');

  const [is3D, setIs3D] = useState(true);

  return (
    <div style={{ padding: '0px 18px' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '75%', marginRight: '16px' }}>
          <TextField
            fullWidth
            label="Project Name"
            required
            margin="normal"
            value={newProjectInfo.project_name}
            autoFocus
            onChange={(event) =>
              setNewProjectInfo({
                ...newProjectInfo,
                project_name: event.target.value,
              })
            }
          />
        </div>
        <div style={{ width: '25%' }}>
          <div
            className="u-switch"
            style={{
              marginTop: '16px',
            }}
          >
            <div
              onClick={() => {
                setIs3D(true);
                setNewProjectInfo({
                  ...newProjectInfo,
                  project_type: '3D',
                });
              }}
              className="u-switchTab"
              style={{
                borderRight: '1px solid #609426',
                backgroundColor: is3D ? '#609426' : null,
                color: is3D ? 'white' : '#cacaca',
              }}
            >
              3D
            </div>
            <div
              onClick={() => {
                setIs3D(false);
                setNewProjectInfo({
                  ...newProjectInfo,
                  project_type: '2D',
                });
              }}
              className="u-switchTab"
              style={{
                borderLeft: '1px solid #609426',
                backgroundColor: is3D ? null : '#609426',
                color: is3D ? '#cacaca' : 'white',
              }}
            >
              2D
            </div>
          </div>
        </div>
      </div>

      <TextField
        fullWidth
        label="Project Description"
        required
        multiline
        rows={1}
        rowsMax={8}
        margin="normal"
        value={newProjectInfo.project_desc}
        onChange={(event) =>
          setNewProjectInfo({
            ...newProjectInfo,
            project_desc: event.target.value,
          })
        }
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          fullWidth
          required
          margin="normal"
          id="date-picker-dialog"
          label="Due Date"
          format="MMM d, yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <TextField
        fullWidth
        label="Address"
        margin="normal"
        value={newProjectInfo.project_map_location}
        onChange={(event) =>
          setNewProjectInfo({
            ...newProjectInfo,
            project_map_location: event.target.value,
          })
        }
      />
      {
        <div className="u-animate-fade" style={{ marginTop: '24px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}
          >
            <b> Attachment</b>
            <Button
              variant="outlined"
              className="u-rowButton"
              onClick={() => {
                setNewProjectInfo({
                  ...newProjectInfo,
                  project_files_link: '',
                });
                setIsAttachmentTypeUpload(!isAttachmentTypeUpload);
              }}
              style={{
                outline: 'none',
                textTransform: 'capitalize',
                border: '0px',
              }}
            >
              {isAttachmentTypeUpload
                ? '+ Add Drive link instead'
                : '↑ Upload files instead'}
            </Button>
          </div>

          {isAttachmentTypeUpload ? (
            <UploaderView
              setSelectedFiles={setSelectedFiles}
              uploadCount={uploadCount}
              uploadStatus={uploadStatus}
            />
          ) : (
            <TextField
              fullWidth
              label="Project Files Link (Google Drive Public link)"
              margin="normal"
              value={newProjectInfo.project_files_link}
              onChange={(event) =>
                setNewProjectInfo({
                  ...newProjectInfo,
                  project_files_link: event.target.value,
                })
              }
            />
          )}
        </div>
      }
    </div>
  );
}

export default FormNewProjects;
