import React, { createRef, useState } from 'react';

import { Button } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { AddOutlined, CancelOutlined } from '@material-ui/icons';

function UploaderView({ setSelectedFiles, uploadStatus, uploadCount }) {
  const dropzoneRef = createRef();

  const [previewFiles, setPreviewFiles] = useState([]);
  const [isUploadingFromDialog, setIsUploadingFromDialog] = useState(true);

  const getFileExtension = (file) =>
    file.type.slice(file.type.lastIndexOf('/') + 1, file.type.length);

  const openDialog = () => {
    setIsUploadingFromDialog(true);
    console.log('opening file dialog');
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const getFile = (e) => {
    setIsUploadingFromDialog(false);
    var files = e.target.files;
    console.log(files);
    var newPreview = [...files, ...previewFiles];
    setPreviewFiles(newPreview);
    setSelectedFiles(newPreview);
  };
  return (
    <div
      style={{
        textAlign: 'center',
        height: 'auto',
        transition: 'height 0.4s',
      }}
    >
      {
        <div className="uploadProject">
          <>
            <Dropzone ref={dropzoneRef} noClick noKeyboard>
              {({ getRootProps, getInputProps, acceptedFiles }) => {
                if (isUploadingFromDialog && acceptedFiles.length > 0) {
                  setSelectedFiles(acceptedFiles);
                  setPreviewFiles(acceptedFiles);
                  setIsUploadingFromDialog(false);
                }

                return (
                  <>
                    {previewFiles.length === 0 && isUploadingFromDialog && (
                      <div
                        {...getRootProps({ className: 'dropzone' })}
                        style={{
                          padding: '80px 0',
                          backgroundColor: '#fcfcfc',
                          border: '1px solid #e0dbdb',
                          borderRadius: '12px',
                        }}
                      >
                        <input {...getInputProps()} />
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#cecece',
                            fontSize: '16px',
                          }}
                        >
                          Drag & drop some files here
                        </p>
                        <Button
                          variant="outlined"
                          className="u-rowButton"
                          onClick={openDialog}
                        >
                          Open File Dialog
                        </Button>
                      </div>
                    )}
                    {!isUploadingFromDialog && (
                      <div style={{ padding: '24px 4px' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'left',
                            }}
                          >
                            {previewFiles.length}{' '}
                            {previewFiles.length === 1 ? 'file ' : 'files '}
                            {uploadStatus === 'done' ? (
                              <>uploaded</>
                            ) : (
                              <>selected</>
                            )}
                          </p>
                        </div>
                        <input
                          id="addfileUploadButton"
                          type="file"
                          hidden
                          onChange={getFile}
                          multiple
                        />
                        <ul
                          style={{
                            display: 'flex',
                            listStyleType: 'none',
                            paddingLeft: '0',
                            overflowX: 'auto',
                          }}
                        >
                          {' '}
                          <li
                            key="addIcon"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={() => {
                              document
                                .getElementById('addfileUploadButton')
                                .click();
                            }}
                          >
                            <div
                              className="uploaderView__backgroundLight"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '64px',
                                width: '64px',
                                margin: '8px',
                                borderRadius: '8px',
                                transition: '0.4s',
                              }}
                            >
                              <AddOutlined />
                            </div>
                          </li>
                          {previewFiles.map((file) => (
                            <li key={file.name + file.type + file.size}>
                              <div
                                className="imageUploadedPreviewOnEditView"
                                style={{ position: 'relative' }}
                              >
                                {file.type === 'image/jpeg' ||
                                file.type === 'image/png' ||
                                file.type === 'image/gif' ? (
                                  <div
                                    style={{
                                      margin: '8px',
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt=""
                                      height={64}
                                      style={{
                                        borderRadius: '8px',
                                        border: '2px solid #b6bbb6',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      height: '64px',
                                      width: '52px',
                                      backgroundColor: '#f9f9f9',
                                      borderRadius: '8px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      margin: '8px',
                                      border: '2px solid #b6bbb6',
                                    }}
                                  >
                                    <span className="fileExt">
                                      {getFileExtension(file)}
                                    </span>
                                  </div>
                                )}
                                <div
                                  className="imageRemoveButton"
                                  style={{
                                    textAlign: 'center',
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '0',
                                    background: 'red',
                                    borderRadius: '50%',
                                    color: 'white',
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      let newList = previewFiles.filter(
                                        (f) => f !== file
                                      );
                                      console.log(newList.length);
                                      setPreviewFiles(newList);
                                      setSelectedFiles(newList);
                                    }}
                                  >
                                    <CancelOutlined />
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        {uploadStatus === 'done' ? (
                          <p style={{ color: '#609426', fontWeight: 'bold' }}>
                            Your files are successfully uploaded!
                          </p>
                        ) : uploadStatus === 'uploading' ? (
                          <>
                            <div
                              style={{
                                width: '100%',
                                height: '8px',
                                backgroundColor: '#bbbbbb',
                                borderRadius: '4px',
                                marginBottom: '12px',
                              }}
                            >
                              <div
                                style={{
                                  transition: '1.0s',
                                  width:
                                    (uploadCount * 100) / previewFiles.length +
                                    '%',
                                  height: '8px',
                                  backgroundColor: '#609426',
                                  borderRadius: '4px',
                                }}
                              ></div>
                            </div>
                            <p>Uploading</p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </>
                );
              }}
            </Dropzone>
          </>
        </div>
      }
    </div>
  );
}

export default UploaderView;
