import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import React, { createContext, useEffect, useState } from 'react';

import ApprovedProjects from './components/approved-projects/ApprovedProjects';
import DesignQueue from './components/design-queue/DesignQueue';
import Designers from './components/designers/Designers';
import FinishedProjects from './components/finished-projects/FinishedProjects';
import Header from './components/Header';
import LandscapeClients from './components/landscape-clients/LandscapeClients';
import Login from './components/login/Login';
import MessageBody from './components/messages/MessageBody';
import Messages from './components/messages/Messages';
import MyProjects from './components/my-projects/MyProjects';
import NewProjects from './components/new-projects/NewProjects';
import PRoute from './widgets/PRoute';
import Sidebar from './components/Sidebar';
import SignUp from './components/signup/SignUp';
import Users from './components/users/Users';
import appLogo from './assets/logo.png';
import db from './firebase/config';
import Dashboard from './components/dashboard/Dashboard';
import Payments from './components/payments/Payments';
import AllPayments from './components/all-payments/AllPayments';

export const UserContext = createContext();
export const ProjectsListContext = createContext();

function App() {
  const [loggedInStatus, setLoggedInStatus] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [appSidebar, setAppSideBar] = useState('sidebar');
  const [userInfo, setUserInfo] = useState({});
  const [projectsList, setProjectsList] = useState({});

  const handleMenuClick = () => {
    console.log('isOpened', isOpened);
    setAppSideBar(isOpened ? 'sidebar' : 'sidebar sidebar__closed');
    isOpened ? setIsOpened(false) : setIsOpened(true);
  };

  useEffect(() => {
    db.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        //the user is logged in

        db.firestore()
          .collection('users')
          .doc(db.auth().currentUser.email)
          .get()
          .then((snapshot) => {
            console.log('checking... auth');
            if (snapshot.exists) {
              console.log('checked... auth exists');

              setLoggedInStatus(true);
              setIsLoaded(true);
            }
          });
      } else {
        //the user is logged out
        setLoggedInStatus(false);
        setIsLoaded(true);
        console.log('logged out');
      }
    });
  }, []);
  console.log(loggedInStatus);

  return isLoaded === true ? (
    loggedInStatus === true ? (
      <UserContext.Provider value={userInfo}>
        <ProjectsListContext.Provider value={projectsList}>
          <div className="App">
            <BrowserRouter>
              <Sidebar onCrossClick={handleMenuClick} className={appSidebar} />

              <div className="app__body">
                <Header
                  onMenuClick={handleMenuClick}
                  setUserInfo={setUserInfo}
                  setProjectsList={setProjectsList}
                />
                <Switch>
                  <Redirect from="/signin" to="/" />
                  <PRoute path="/landscape-clients">
                    <LandscapeClients />
                  </PRoute>
                  <PRoute path="/designers">
                    <Designers />
                  </PRoute>
                  <PRoute path="/payments">
                    <Payments />
                  </PRoute>
                  <PRoute path="/all-payments">
                    <AllPayments />
                  </PRoute>
                  <PRoute path="/messages/read">
                    <MessageBody />
                  </PRoute>
                  <PRoute path="/messages">
                    <Messages />
                  </PRoute>

                  <PRoute path="/designer/my-projects">
                    <MyProjects />
                  </PRoute>

                  <PRoute path="/projects/new">
                    <NewProjects />
                  </PRoute>

                  <PRoute path="/projects/queue">
                    <DesignQueue />
                  </PRoute>

                  <PRoute path="/projects/approved">
                    <ApprovedProjects />
                  </PRoute>

                  <PRoute path="/projects/finished">
                    <FinishedProjects />
                  </PRoute>

                  <PRoute path="/projects">
                    <NewProjects />
                  </PRoute>

                  <PRoute path="/users">
                    <Users />
                  </PRoute>

                  <PRoute path="/dashboard">
                    <Dashboard />
                  </PRoute>

                  <PRoute path="/">
                    <Dashboard />
                  </PRoute>
                </Switch>
              </div>
            </BrowserRouter>
          </div>
        </ProjectsListContext.Provider>
      </UserContext.Provider>
    ) : (
      <>
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route path="/signin">
                <Login />
              </Route>

              <Route path="/signup">
                <SignUp />
              </Route>

              <Redirect from="/" to="/signin" />
            </Switch>
          </BrowserRouter>
        </div>
      </>
    )
  ) : (
    <div className="App centerItem">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={appLogo}
          height="64"
          style={{
            borderRadius: '12px',
            boxShadow: '0px 0px 16px 2px #b5b5b5',
          }}
          alt=""
        />
        <br />

        <CircularProgress size={24} thickness={5} />
      </div>
    </div>
  );
}

export default App;
