import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';

import MaterialTable from 'material-table';
import NotificationShow from '../../widgets/NotificationShow';
import ProjectDetailsView from '../new-projects/ProjectDetailsView';
import ProjectUploadView from '../my-projects/ProjectUploadView';
import { UserContext } from '../../App';
import { ProjectsListContext } from '../../App';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import loading from '../../assets/loading.gif';
import moment from 'moment';
import tableIcons from '../../table/Table-init';

const DesignQueue = () => {
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValueList, setSearchValueList] = useState([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');

  const userInfo = useContext(UserContext);
  const projectsListInfo = useContext(ProjectsListContext);

  const [openDetailView, setOpenDetailView] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

  const [scroll, setScroll] = useState('paper');

  const [currentRowData, setCurrentRowData] = useState({});
  const [isUploadProjectDialogOpen, setIsUploadProjectDialogOpen] =
    useState(false);

  const OptionList = (props) => {
    return props.optionList.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  };

  const handleChange = (e) => {
    console.log('handleChange -> handleChange called');
    setSelectedSearchValue(e.target.value);
    let filteredLogList = projectList.filter((list) =>
      e.target.value === '' ? list : list.company_name === e.target.value
    );

    setFilteredList(filteredLogList);
    console.log(e.target.value);
  };

  const openProjectDetailModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setOpenDetailView(true);
    setScroll(scrollType);
  };

  const handleCloseView = () => {
    setOpenDetailView(false);
  };

  // approve helper functions

  const openConfirmApproveModal = (scrollType, rowData) => () => {
    setIsOpenApproveModal(true);
    setCurrentRowData(rowData);
    setScroll(scrollType);
  };

  const closeApproveModal = () => {
    setIsOpenApproveModal(false);
  };

  const approveProjects = () => {
    setIsOpenApproveModal(false);
    console.log(currentRowData);
    db.firestore()
      .collection('projects')
      .doc(currentRowData.created)
      .set(
        {
          is_approved: true,
          status: 'Approved',
        },
        { merge: true }
      )
      .then(() => {
        setIsNotificationOpen(true);
      });
  };

  //reject helper functions
  const openConfirmRejectModal = (scrollType, rowData) => () => {
    setIsOpenRejectModal(true);
    setCurrentRowData(rowData);
    setScroll(scrollType);
  };

  const closeRejectModal = () => {
    setIsOpenRejectModal(false);
  };

  const rejectProjects = () => {
    setIsOpenRejectModal(false);
    console.log(currentRowData);
    db.firestore()
      .collection('projects')
      .doc(currentRowData.created)
      .set(
        {
          is_approved: false,
          status: 'Rejected',
        },
        { merge: true }
      )
      .then(() => {
        setIsNotificationOpen(true);
      });
  };

  const openUploadProjectDialog = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setScroll(scrollType);
    setIsUploadProjectDialogOpen(true);
  };

  const closeUploadProjectDialog = () => {
    setIsUploadProjectDialogOpen(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedList = [];

    if (userInfo.email) {
      if (projectsListInfo.length > 0) {
        loadedList = [...projectsListInfo].filter(
          (item) =>
            item.status === 'Work in progress' ||
            item.status === 'Waiting for review' ||
            item.status === 'Rejected'
        );
        let loadedCompanyNameList = [
          ...new Set(loadedList.map((item) => item.company_name)),
        ];

        setSearchValueList(loadedCompanyNameList);
        setProjectList(loadedList);
        setFilteredList(loadedList);
      }
      setIsLoading(false);
    }
  }, [projectsListInfo, userInfo.email]);

  const descriptionElementRefDetail = useRef(null);
  const descriptionElementRefUpload = useRef(null);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  return (
    <>
      <div className="body">
        <NotificationShow
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
        />

        <div className="body__toolbar">
          <FormControl className="log__selectRoot">
            <InputLabel htmlFor="age-native-simple">
              Filter by Company{' '}
            </InputLabel>
            <Select
              native
              value={selectedSearchValue}
              onChange={handleChange}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <OptionList optionList={searchValueList} />
            </Select>
          </FormControl>
        </div>

        {/* detail dialog */}
        <Dialog
          className="projectDetail__dialog"
          open={openDetailView}
          onClose={handleCloseView}
          scroll={scroll}
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-detail-description"
              ref={descriptionElementRefDetail}
              tabIndex={-1}
            >
              <ProjectDetailsView
                detail={currentRowData}
                closeDialog={handleCloseView}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* connfirm dialog */}
        <Dialog
          className="u-medium-dialog projectApprove__dialog"
          open={isOpenApproveModal}
          onClose={closeApproveModal}
          scroll={scroll}
          aria-labelledby="scroll-dialog-approve-title"
          aria-describedby="scroll-dialog-approve-description"
        >
          <DialogTitle id="scroll-dialog-approve-title">
            Approve this project?
          </DialogTitle>
          <DialogContent>
            <div id="scroll-dialog-approve-description">
              Approving this project will move this project to "Approved
              Projects" list. <br /> <br /> Do you want to approve this ?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ outline: 'none' }}
              onClick={closeApproveModal}
              color="primary"
            >
              No, go back
            </Button>
            <Button
              style={{ outline: 'none' }}
              onClick={approveProjects}
              color="primary"
              autoFocus
            >
              Yes, Approve
            </Button>
          </DialogActions>
        </Dialog>

        {/* reject dialog */}
        <Dialog
          className="u-medium-dialog projectReject__dialog"
          open={isOpenRejectModal}
          onClose={closeRejectModal}
          scroll={scroll}
          aria-labelledby="scroll-dialog-reject-title"
          aria-describedby="scroll-dialog-reject-description"
        >
          <DialogTitle id="scroll-dialog-reject-title">
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <div id="scroll-dialog-reject-description">
              Do you want to reject this project ?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ outline: 'none' }}
              onClick={closeRejectModal}
              color="primary"
            >
              No, go back
            </Button>
            <Button
              style={{ outline: 'none' }}
              onClick={rejectProjects}
              color="primary"
              autoFocus
            >
              Yes, Reject
            </Button>
          </DialogActions>
        </Dialog>

        {/* upload dialog */}
        <Dialog
          className="u-large-dialog"
          open={isUploadProjectDialogOpen}
          onClose={closeUploadProjectDialog}
          scroll={scroll}
          aria-labelledby="scroll-dialog-upload-title"
          aria-describedby="scroll-dialog-upload-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-upload-description"
              ref={descriptionElementRefUpload}
              tabIndex={-1}
            >
              <ProjectUploadView
                detail={currentRowData}
                setIsNotificationOpen={setIsNotificationOpen}
                setIsUploadProjectDialogOpen={setIsUploadProjectDialogOpen}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeUploadProjectDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          title={`Design Queue (${filteredList.length})`}
          className="body__table"
          icons={tableIcons}
          data={filteredList}
          columns={[
            {
              title: 'Project Name',
              field: 'project_name',
              render: (rowData) => (
                <div
                  onClick={openProjectDetailModal('paper', rowData)}
                  className="rowProject__Title"
                >
                  <span
                    style={{
                      fontSize: '11px',
                      marginRight: '12px',
                      borderRadius: '4px',
                      padding: '3px',
                      backgroundColor: rowData.project_type
                        ? rowData.project_type === '3D'
                          ? '#00a006'
                          : '#8c9da5'
                        : '#00a006',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {rowData.project_type ? rowData.project_type : '3D'}
                  </span>
                  <span>{rowData.project_name}</span>
                </div>
              ),
            },

            {
              title: 'Company Name',
              field: 'company_name',
            },

            {
              title: 'Due Date',
              field: 'project_due_date',

              render: (rowData) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      backgroundColor:
                        moment(rowData.project_due_date, 'MMM D, yyyy').diff(
                          moment(),
                          'hours'
                        ) < 1
                          ? 'red'
                          : '#26cc26',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />

                  <span>{rowData.project_due_date}</span>
                </div>
              ),
            },

            { title: 'Assigned to', field: 'assigned_to_name' },

            {
              title: 'Status',
              field: 'status',
              render: (rowData) => (
                <>
                  <span
                    className="u-badge"
                    style={{
                      background:
                        rowData.status === 'Rejected'
                          ? '#ffe6e6'
                          : rowData.status === 'Waiting for review'
                          ? '#ffefe6'
                          : '#d9d9ff',

                      color:
                        rowData.status === 'Rejected'
                          ? '#d60f0f'
                          : rowData.status === 'Waiting for review'
                          ? '#ad5119'
                          : '#4d4dbf',
                    }}
                  >
                    {rowData.status}
                    {rowData.status === 'Work in progress' && (
                      <>
                        <img
                          className="u-hide-me-on-small-devices"
                          src={loading}
                          width={16}
                          height={16}
                          style={{
                            opacity: '0.9',
                            borderRadius: '8px',
                            backgroundColor: 'purple',
                            marginLeft: '2px',
                          }}
                        />
                      </>
                    )}
                  </span>
                </>
              ),
            },

            userInfo.user_role && userInfo.user_role != 'designer'
              ? {
                  title: 'Actions',
                  export: false,
                  field: 'project_name',
                  render: (rowData) => {
                    return (
                      <>
                        <Button
                          className="u-rowButton button__action"
                          disabled={rowData.status == 'Work in progress'}
                          variant="outlined"
                          onClick={openConfirmApproveModal('paper', rowData)}
                        >
                          Approve
                        </Button>

                        {rowData.status == 'Waiting for review' && (
                          <Button
                            className="u-rowButton button__actionDanger"
                            variant="outlined"
                            onClick={openConfirmRejectModal('paper', rowData)}
                          >
                            Reject
                          </Button>
                        )}
                      </>
                    );
                  },
                }
              : {
                  title: 'Actions',
                  export: false,
                  field: 'project_name',
                  render: (rowData) => {
                    return (
                      <>
                        <Button
                          className="u-rowButton"
                          variant="outlined"
                          onClick={openProjectDetailModal('paper', rowData)}
                        >
                          View
                        </Button>
                        {rowData.status === 'Work in progress' ||
                        rowData.status === 'Rejected' ? (
                          <Button
                            className="u-rowButton button__action"
                            style={{ outline: 'none', margin: '1px' }}
                            variant="outlined"
                            onClick={openUploadProjectDialog('paper', rowData)}
                          >
                            Submit
                          </Button>
                        ) : (
                          <span></span>
                        )}
                      </>
                    );
                  },
                },
          ]}
          options={{
            exportButton: true,
            pageSizeOptions: [20, 30, 40, 60],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default DesignQueue;
