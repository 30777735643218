import React, { useContext } from 'react';

import Center from './Center';
import { CircularProgress } from '@material-ui/core';
import Error404 from '../components/404/404';
import { UserContext } from '../App';

function AccessOnly({ children, userType }) {
  const userInfo = useContext(UserContext);
  return (
    <>
      {userInfo.user_role ? (
        (userType === 'admin' && userInfo.is_admin === true) ||
        userInfo.user_role === userType ? (
          <>{children}</>
        ) : (
          <Error404 />
        )
      ) : (
        <Center>
          <CircularProgress />
        </Center>
      )}
    </>
  );
}

export default AccessOnly;
