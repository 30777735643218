import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';

import MaterialTable from 'material-table';
import ProjectDetailsView from '../new-projects/ProjectDetailsView';

import { UserContext } from '../../App';
import { ProjectsListContext } from '../../App';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import loading from '../../assets/loading.gif';
import moment from 'moment';
import tableIcons from '../../table/Table-init';
import '../../css/dashboard.css';
import '../../App.css';
import { Link } from 'react-router-dom';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const Dashboard = () => {
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValueList, setSearchValueList] = useState([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');

  const userInfo = useContext(UserContext);
  const projectsListInfo = useContext(ProjectsListContext);
  const [openDetailView, setOpenDetailView] = useState(false);

  const [scroll, setScroll] = useState('paper');

  const [currentRowData, setCurrentRowData] = useState({});

  const OptionList = (props) => {
    return props.optionList.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  };

  const handleChange = (e) => {
    console.log('handleChange -> handleChange called');
    setSelectedSearchValue(e.target.value);
    let filteredLogList = projectList.filter((list) =>
      e.target.value === '' ? list : list.assigned_to_name === e.target.value
    );

    setFilteredList(filteredLogList);
    console.log(e.target.value);
  };

  const openProjectDetailModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setOpenDetailView(true);
    setScroll(scrollType);
  };

  const handleCloseView = () => {
    setOpenDetailView(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  const [newProjectCount, setNewProjectCount] = useState(0);
  const [designQueueCount, setDesignQueueCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [finishedCount, setFinishedCount] = useState(0);

  useEffect(() => {
    let loadedList = [];
    if (userInfo.email) {
      if (projectsListInfo.length > 0) {
        loadedList = [...projectsListInfo];
        let loadedDesignerNameList = [
          ...new Set(
            loadedList
              .map((item) => item.assigned_to_name)
              .filter((item) => typeof item !== 'undefined')
          ),
        ];

        setSearchValueList(loadedDesignerNameList);
        setProjectList(loadedList);
        setFilteredList(loadedList);

        setNewProjectCount(
          loadedList.filter((item) => item.status === 'Not assigned').length
        );

        setDesignQueueCount(
          loadedList.filter(
            (item) =>
              item.status === 'Work in progress' ||
              item.status === 'Rejected' ||
              item.status === 'Waiting for review'
          ).length
        );

        setApprovedCount(
          loadedList.filter((item) => item.status === 'Approved').length
        );

        setFinishedCount(
          loadedList.filter((item) => item.status === 'Finished').length
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSearchValueList([]);
        setProjectList([]);
        setFilteredList([]);
        setNewProjectCount(0);
        setDesignQueueCount(0);
        setApprovedCount(0);
        setFinishedCount(0);
      }
    }
  }, [projectsListInfo, userInfo.email]);

  const descriptionElementRefDetail = useRef(null);

  const getDynamicState = () => {
    var approved = filteredList.filter(
      (item) => item.status === 'Approved'
    ).length;
    var rejected = filteredList.filter(
      (item) => item.status === 'Rejected'
    ).length;
    var inProgress = filteredList.filter(
      (item) => item.status === 'Work in progress'
    ).length;
    var inReview = filteredList.filter(
      (item) => item.status === 'Waiting for review'
    ).length;
    var finished = filteredList.filter(
      (item) => item.status === 'Finished'
    ).length;

    var designQueue = filteredList.filter(
      (item) =>
        item.status === 'Work in progress' ||
        item.status === 'Waiting for review' ||
        item.status === 'Rejected'
    ).length;

    return {
      approved: approved,
      rejected: rejected,
      inProgress: inProgress,
      inReview: inReview,
      finished: finished,
      designQueue: designQueue,
    };
  };

  return (
    <>
      <div
        className="body"
        style={{ opacity: isLoading ? '0.5' : '1.0', transition: '0.4s' }}
      >
        <h2
          style={{
            marginTop: '12px',
            marginBottom: '24px',
            fontWeight: 'bold',
            color: '#949494 !important',
          }}
        >
          Dashboard
        </h2>

        <h6
          style={{
            fontWeight: 'bold',
            letterSpacing: '2px',
            color: '#a0a0a0',
            paddingBottom: '16px',
          }}
        >
          OVERVIEW
        </h6>

        <div className="dashboard__overview">
          {userInfo.user_role !== 'designer' && (
            <div className="dashboard__overviewCard">
              <span>{newProjectCount}</span>
              <p>New Project</p>
            </div>
          )}
          <div className="dashboard__overviewCard">
            <span>{designQueueCount}</span>
            <p>Design Queue</p>
          </div>
          <div className="dashboard__overviewCard">
            <span>{approvedCount}</span>
            <p>Approved</p>
          </div>
          <div className="dashboard__overviewCard">
            <span>{finishedCount}</span>
            <p>Finished</p>
          </div>
        </div>

        <div className="body__toolbar">
          <h6
            style={{
              fontWeight: 'bold',
              letterSpacing: '2px',
              color: '#a0a0a0',
              paddingTop: '16px',
            }}
          >
            DESIGNER STATISTICS
          </h6>
          <FormControl className="log__selectRoot">
            <InputLabel htmlFor="age-native-simple">
              Filter by Designer{' '}
            </InputLabel>
            <Select
              native
              value={selectedSearchValue}
              onChange={handleChange}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <OptionList optionList={searchValueList} />
            </Select>
          </FormControl>
        </div>
        <div className="dashboard__statistics">
          <div className="dashboard__timeLine">
            <div className="dashboard__timeLineEvent">
              <div className="dashboard__timelinePoint">
                <div className="dashboard__timelinePointCircle"></div>
                <div className="dashboard__timelinePointLine"></div>
              </div>
              <div className="dashboard__timelineContent">
                <Link to="/projects/queue">
                  <h6 className="dashboard__timlineH6">
                    <span className="u-animate-fade dashboard__counterHighlight bg-queue">
                      {getDynamicState().designQueue}
                    </span>
                    {'  '}
                    Design Queue
                  </h6>
                </Link>
                <div>
                  <div>
                    <span>
                      <span style={{ fontWeight: 'bold', color: 'green' }}>
                        {getDynamicState().inProgress}{' '}
                      </span>
                      {' • '}
                      Work in Progress{' '}
                    </span>
                  </div>
                  <div>
                    <span>
                      <span style={{ fontWeight: 'bold', color: 'green' }}>
                        {getDynamicState().inReview}
                      </span>
                      {' • '}
                      Waiting for Review{' '}
                    </span>
                  </div>
                  <div>
                    <span>
                      <span style={{ fontWeight: 'bold', color: 'green' }}>
                        {getDynamicState().rejected}
                      </span>
                      {' • '}
                      Rejected{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard__timeLineEvent">
              <div className="dashboard__timelinePoint">
                <div className="dashboard__timelinePointCircle"></div>
                <div className="dashboard__timelinePointLine"></div>
              </div>
              <div className="dashboard__timelineContent">
                <Link to="/projects/approved">
                  <h6 className="dashboard__timlineH6 ">
                    <span className="u-animate-fade dashboard__counterHighlight bg-approved">
                      {' '}
                      {getDynamicState().approved}
                    </span>{' '}
                    Approved
                  </h6>
                </Link>
              </div>
            </div>

            <div className="dashboard__timeLineEvent">
              <div className="dashboard__timelinePoint">
                <div className="dashboard__timelinePointCircle"></div>
                <div className="dashboard__timelinePointLine"></div>
              </div>
              <div className="dashboard__timelineContent">
                <Link to="/projects/finished">
                  <h6 className="dashboard__timlineH6 ">
                    <span className="u-animate-fade dashboard__counterHighlight bg-finished">
                      {' '}
                      {getDynamicState().finished}
                    </span>
                    {'  '}
                    Finished
                  </h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard__rejection">
            <div className="circularProgressBar">
              <CircularProgressWithLabel
                className="circularProgressBarCircle"
                size={144}
                value={
                  getDynamicState().rejected +
                    getDynamicState().approved +
                    getDynamicState().finished ===
                  0
                    ? 0
                    : (getDynamicState().rejected /
                        (getDynamicState().rejected +
                          getDynamicState().approved +
                          getDynamicState().finished)) *
                      100
                }
              />
              <p style={{ color: '#949494', marginTop: '12px' }}>
                Rejection Rate
              </p>
            </div>
          </div>
        </div>
        {/* detail dialog */}
        <Dialog
          className="projectDetail__dialog"
          open={openDetailView}
          onClose={handleCloseView}
          scroll={scroll}
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-detail-description"
              ref={descriptionElementRefDetail}
              tabIndex={-1}
            >
              <ProjectDetailsView
                detail={currentRowData}
                closeDialog={handleCloseView}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          title={`Projects List (${filteredList.length})`}
          className="body__table"
          icons={tableIcons}
          data={filteredList}
          columns={[
            {
              title: 'Project Name',
              field: 'project_name',
              render: (rowData) => (
                <div
                  onClick={openProjectDetailModal('paper', rowData)}
                  className="rowProject__Title"
                >
                  <span
                    style={{
                      fontSize: '11px',
                      marginRight: '12px',
                      borderRadius: '4px',
                      padding: '3px',
                      backgroundColor: rowData.project_type
                        ? rowData.project_type === '3D'
                          ? '#00a006'
                          : '#8c9da5'
                        : '#00a006',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {rowData.project_type ? rowData.project_type : '3D'}
                  </span>
                  <span>{rowData.project_name}</span>
                </div>
              ),
            },

            {
              title: 'Company Name',
              field: 'company_name',
            },

            {
              title: 'Due Date',
              field: 'project_due_date',

              render: (rowData) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      backgroundColor:
                        moment(rowData.project_due_date, 'MMM D, yyyy').diff(
                          moment(),
                          'hours'
                        ) < 1
                          ? 'red'
                          : '#26cc26',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />

                  <span>{rowData.project_due_date}</span>
                </div>
              ),
            },

            { title: 'Assigned to', field: 'assigned_to_name' },

            {
              title: 'Status',
              field: 'status',
              render: (rowData) => (
                <>
                  <span
                    className="u-badge"
                    style={{
                      background:
                        rowData.status === 'Not assigned'
                          ? '#d6d6d6'
                          : rowData.status === 'Rejected'
                          ? '#ffe6e6'
                          : rowData.status === 'Waiting for review'
                          ? '#ffefe6'
                          : rowData.status === 'Work in progress'
                          ? '#d9d9ff'
                          : rowData.status === 'Finished'
                          ? '#cef9f5'
                          : rowData.status === 'Approved'
                          ? '#edffd9'
                          : '#d9d9ff',

                      color:
                        rowData.status === 'Not assigned'
                          ? '#828282'
                          : rowData.status === 'Rejected'
                          ? '#d60f0f'
                          : rowData.status === 'Waiting for review'
                          ? '#ad5119'
                          : rowData.status === 'Work in progress'
                          ? '#4d4dbf'
                          : rowData.status === 'Finished'
                          ? '#287390'
                          : rowData.status === 'Approved'
                          ? '#338036'
                          : '#d9d9ff',
                    }}
                  >
                    {rowData.status}
                    {rowData.status === 'Work in progress' && (
                      <>
                        <img
                          className="u-hide-me-on-small-devices"
                          src={loading}
                          width={16}
                          height={16}
                          style={{
                            opacity: '0.9',
                            borderRadius: '8px',
                            backgroundColor: 'purple',
                            marginLeft: '2px',
                          }}
                        />
                      </>
                    )}
                  </span>
                </>
              ),
            },
          ]}
          options={{
            exportButton: true,
            pageSizeOptions: [20, 30, 40, 60],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Dashboard;
