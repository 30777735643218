import React, { useCallback, useState } from 'react';

import ImageViewer from 'react-simple-image-viewer';

function ScrollableGallery({ filesList }) {
  const getFileExtensionFromFirebaseUrl = (file) =>
    file.slice(file.lastIndexOf('.') + 1, file.lastIndexOf('?'));

  let imageFiles = filesList.filter(
    (file) =>
      getFileExtensionFromFirebaseUrl(file) === 'jpg' ||
      getFileExtensionFromFirebaseUrl(file) === 'jpeg' ||
      getFileExtensionFromFirebaseUrl(file) === 'png' ||
      getFileExtensionFromFirebaseUrl(file) === 'gif'
  );

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <div>
      <ul
        style={{
          display: 'flex',
          listStyleType: 'none',
          padding: '12px 0',
          overflowX: 'auto',
        }}
      >
        {imageFiles.map((file, index) => (
          <li key={file}>
            <img
              src={file}
              alt=""
              height={64}
              width={76}
              onClick={() => openImageViewer(index)}
              style={{
                marginRight: '8px',
                borderRadius: '8px',
                border: '2px solid #b6bbb6',
                minWidth: '64px',
                objectFit: 'cover',
                transition: '1s',
              }}
            />
          </li>
        ))}

        {filesList.map((file) => (
          <li key={file}>
            {getFileExtensionFromFirebaseUrl(file) === 'jpg' ||
            getFileExtensionFromFirebaseUrl(file) === 'jpeg' ||
            getFileExtensionFromFirebaseUrl(file) === 'png' ||
            getFileExtensionFromFirebaseUrl(file) === 'gif' ? (
              <></>
            ) : (
              <a target="_blank" href={file}>
                <div className="scrollable__fileType">
                  <span className="fileExt">
                    {getFileExtensionFromFirebaseUrl(file)}
                  </span>
                </div>
              </a>
            )}
          </li>
        ))}

        {isViewerOpen && (
          <ImageViewer
            style={{ width: 'min(65%, 450px)' }}
            src={imageFiles}
            currentIndex={
              currentImage > imageFiles.length - 1
                ? imageFiles.length - 1
                : currentImage
            }
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
          ></ImageViewer>
        )}
      </ul>
    </div>
  );
}

export default ScrollableGallery;
