import {
  AddOutlined,
  CancelOutlined,
  DescriptionOutlined,
  EditOutlined,
  SaveRounded,
} from '@material-ui/icons';

import {
  Avatar,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import React, { useState } from 'react';

import moment from 'moment';

import db from '../firebase/config';

function ProjectDetailsEditView({ detail, closeDialog }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState(
    detail.project_files ? detail.project_files : []
  );

  const [pickedFiles, setPickedFiles] = useState([]);

  const [projName, setProjName] = useState(detail.project_name);
  const [projDesc, setProjDesc] = useState(detail.project_desc);
  const [projMapLocation, setProjMapLocation] = useState(
    detail.project_map_location
  );
  const [projFilesLink, setProjFilesLink] = useState(detail.project_files_link);

  const getFileExtension = (file) =>
    file.type.slice(file.type.lastIndexOf('/') + 1, file.type.length);

  const getFileExtensionFromUrl = (file) => {
    return file.slice(file.lastIndexOf('.') + 1, file.lastIndexOf('?'));
  };

  const handleProjNameInput = (e) => {
    setProjName(e.target.value);
  };
  const handleProjDescInput = (e) => {
    setProjDesc(e.target.value);
  };
  const handleProjMapLocationInput = (e) => {
    setProjMapLocation(e.target.value);
  };

  const handleProjFilesLinkInput = (e) => {
    setProjFilesLink(e.target.value);
  };

  const getFile = (e) => {
    var files = e.target.files;

    var newPreview = [...files, ...pickedFiles];
    setPickedFiles(newPreview);
  };

  return (
    <>
      <div>
        <div>
          <h4
            style={{
              color: '#609426',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                marginRight: '12px',
                borderRadius: '4px',
                padding: '2px 6px',
                backgroundColor: '#609426',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {detail.project_type ? detail.project_type : '3D'}
            </span>
            {!isEditing ? (
              projName
            ) : (
              <input
                type="text"
                value={projName}
                style={{
                  padding: '8px',
                  border: '1px solid #e2e2e2',
                  borderRadius: '8px',
                  color: '#609426',
                  fontWeight: 'bold',
                }}
                onChange={handleProjNameInput}
              ></input>
            )}
            <IconButton
              onClick={() => {
                setIsEditing(!isEditing);
              }}
            >
              {isEditing ? <SaveRounded /> : <EditOutlined />}
            </IconButton>
            <span
              className="u-badge"
              style={{
                marginLeft: '12px',
                background:
                  detail.status === 'Rejected'
                    ? '#ffe6e6'
                    : detail.status === 'Waiting for review'
                    ? '#ffefe6'
                    : detail.status === 'Not assigned'
                    ? '#eaeaea'
                    : detail.status === 'Approved'
                    ? '#edffd9'
                    : detail.status === 'Finished'
                    ? '#cef9f5'
                    : '#d9d9ff',
                color:
                  detail.status === 'Rejected'
                    ? '#d60f0f'
                    : detail.status === 'Waiting for review'
                    ? '#ad5119'
                    : detail.status === 'Not assigned'
                    ? '#949494'
                    : detail.status === 'Approved'
                    ? '#338036'
                    : detail.status === 'Finished'
                    ? '#287390'
                    : '#4d4dbf',
              }}
            >
              {detail.status}
            </span>
          </h4>
          <span className="u-text" style={{ fontWeight: 'bold' }}>
            {detail.company_name}
          </span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Posted on{' '}
              {moment(detail.posted_on, 'MMM D, yyyy hh:mm:ss a').format(
                'MMM D, yyyy'
              )}
            </span>
            <span>
              {moment(detail.project_due_date, 'MMM D, yyyy').diff(
                moment(),
                'hours'
              ) < 1 ? (
                <>
                  <span
                    className="u-badge"
                    style={{
                      background: '#cc0000',
                      color: '#f5f0f0',
                    }}
                  >
                    OVERDUE
                  </span>{' '}
                  <span
                    style={{
                      color: '#cc0000',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {detail.project_due_date}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="u-badge"
                    style={{
                      background: '#609426',
                      color: 'white',
                    }}
                  >
                    {' '}
                    DUE DATE
                  </span>{' '}
                  <span
                    style={{
                      color: '#3c8a51',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {detail.project_due_date}
                  </span>
                </>
              )}
            </span>
          </div>
          {detail.is_assigned && (
            <div
              style={{
                width: '100%',

                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  padding: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  style={{
                    background: '#609426',
                    width: '28px',
                    height: '28px',
                  }}
                >
                  {detail.assigned_to_name[0]}
                </Avatar>
              </div>
              <div
                className="u-text"
                style={{ padding: '6px', fontWeight: 'bold' }}
              >
                {detail.assigned_to_name}
              </div>
              <span style={{ fontSize: '11px' }}>(Assigned Designer)</span>
            </div>
          )}
          <hr />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <b
            className="u-head-text"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <DescriptionOutlined style={{ marginRight: '8px' }} /> Project
            Description
          </b>

          {isSaving ? (
            <CircularProgress />
          ) : (
            <Button
              variant="outlined"
              className="u-rowButton button__primary"
              onClick={async () => {
                setIsSaving(true);
                let imageList = [];
                let count = 0;
                if (pickedFiles.length > 0) {
                  let currentDateString = Date.now().toString();
                  pickedFiles.forEach(async (file) => {
                    db.storage()
                      .ref(currentDateString)
                      .child(currentDateString + '-' + file.name)
                      .put(file)
                      .on(
                        'state_changed',
                        (progress) => {
                          console.log('progress');
                          console.log(progress);
                        },
                        (error) => {
                          console.log('error');
                          console.log(error);
                        },
                        (complete) => {
                          console.log('complete');
                          console.log(complete);
                          db.storage()
                            .ref(currentDateString)
                            .child(currentDateString + '-' + file.name)
                            .getDownloadURL()
                            .then(async (imageUrl) => {
                              imageList = [...imageList, imageUrl];
                              count++;
                              // setUploadCount(count);
                              if (pickedFiles.length === count) {
                                console.log('upload count must be same now');
                                var projectFiles = [
                                  ...imageList,
                                  ...selectedFiles,
                                ];
                                db.firestore()
                                  .collection('projects')
                                  .doc(detail.created)
                                  .set(
                                    {
                                      project_name: projName,
                                      project_desc: projDesc,
                                      project_map_location: projMapLocation,
                                      project_files: projectFiles,
                                      project_files_link: projFilesLink,
                                    },
                                    { merge: true }
                                  )
                                  .then(() => {
                                    setIsSaving(false);
                                    closeDialog();
                                  });
                              }
                            });
                        }
                      );
                  });
                } else {
                  db.firestore()
                    .collection('projects')
                    .doc(detail.created)
                    .set(
                      {
                        project_name: projName,
                        project_desc: projDesc,
                        project_map_location: projMapLocation,
                        project_files: selectedFiles,
                        project_files_link: projFilesLink,
                      },
                      { merge: true }
                    )
                    .then(() => {
                      setIsSaving(false);
                      closeDialog();
                    });
                }
              }}
            >
              Save all
            </Button>
          )}
        </div>
        <div
          style={{
            background: '#fbfbfb',
            padding: '12px',
            borderRadius: '8px',
            margin: '12px 0px 24px',
          }}
        >
          <textarea
            style={{
              lineHeight: '1.8',
              width: 'calc(100% - 16px)',
              height: '200px',
              padding: '8px',
              border: '1px solid #e2e2e2',
              borderRadius: '8px',
            }}
            value={projDesc}
            onChange={handleProjDescInput}
          />

          <div>
            <b className="u-text">Location</b>
          </div>
          <p style={{ paddingTop: '8px' }}>
            <input
              style={{
                lineHeight: '1.8',
                width: 'calc(100% - 16px)',
                padding: '8px',
                border: '1px solid #e2e2e2',
                borderRadius: '8px',
              }}
              value={projMapLocation}
              onChange={handleProjMapLocationInput}
            />
          </p>

          <>
            <b className="u-text">File Link</b>
            <p style={{ paddingTop: '8px' }}>
              <input
                style={{
                  lineHeight: '1.8',
                  width: 'calc(100% - 16px)',
                  padding: '8px',
                  border: '1px solid #e2e2e2',
                  borderRadius: '8px',
                }}
                value={projFilesLink}
                onChange={handleProjFilesLinkInput}
              />
            </p>
          </>

          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <b className="u-text">Project Files</b>
            </div>

            <div style={{ padding: '24px 4px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  {selectedFiles.length + pickedFiles.length}{' '}
                  {selectedFiles.length + pickedFiles.length === 1
                    ? 'file '
                    : 'files '}
                  {/* {uploadStatus === 'done' ? <>uploaded</> : <>selected</>} */}
                </p>
              </div>
              <input
                id="addfileUploadButton"
                type="file"
                hidden
                onChange={getFile}
                multiple
              />
              <ul
                style={{
                  display: 'flex',
                  listStyleType: 'none',
                  paddingLeft: '0',
                  overflowX: 'auto',
                }}
              >
                <li
                  key="addIcon"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    document.getElementById('addfileUploadButton').click();
                  }}
                >
                  <div
                    className="uploaderView__backgroundLight"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '64px',
                      width: '64px',
                      margin: '8px',
                      borderRadius: '8px',
                      transition: '0.4s',
                    }}
                  >
                    <AddOutlined />
                  </div>
                </li>
                {pickedFiles.length > 0 &&
                  pickedFiles.map((file) => (
                    <li key={file.name + file.type + file.size}>
                      <div
                        className="imageUploadedPreviewOnEditView"
                        style={{ position: 'relative' }}
                      >
                        {file.type === 'image/jpeg' ||
                        file.type === 'image/png' ||
                        file.type === 'image/gif' ? (
                          <div
                            style={{
                              margin: '8px',
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt=""
                              height={64}
                              style={{
                                borderRadius: '8px',
                                border: '2px solid #b6bbb6',
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              height: '64px',
                              width: '52px',
                              backgroundColor: '#f9f9f9',
                              borderRadius: '8px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '8px',
                              border: '2px solid #b6bbb6',
                            }}
                          >
                            <span className="fileExt">
                              {getFileExtension(file)}
                            </span>
                          </div>
                        )}
                        <div
                          className="imageRemoveButton"
                          style={{
                            textAlign: 'center',
                            position: 'absolute',
                            top: '-8px',
                            right: '0',
                            background: 'red',
                            borderRadius: '50%',
                            color: 'white',
                          }}
                        >
                          <div
                            onClick={() => {
                              let newList = pickedFiles.filter(
                                (f) => f !== file
                              );
                              setPickedFiles(newList);
                            }}
                          >
                            <CancelOutlined />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                {selectedFiles.length > 0 &&
                  selectedFiles.map((file) => (
                    <li key={file}>
                      <div
                        className="imageUploadedPreviewOnEditView"
                        style={{ position: 'relative' }}
                      >
                        {getFileExtensionFromUrl(file) === 'jpeg' ||
                        getFileExtensionFromUrl(file) === 'jpg' ||
                        getFileExtensionFromUrl(file) === 'png' ||
                        getFileExtensionFromUrl(file) === 'gif' ? (
                          <div
                            style={{
                              margin: '8px',
                            }}
                          >
                            <img
                              src={file}
                              alt=""
                              height={64}
                              style={{
                                borderRadius: '8px',
                                border: '2px solid #b6bbb6',
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              height: '64px',
                              width: '52px',
                              backgroundColor: '#f9f9f9',
                              borderRadius: '8px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '8px',
                              border: '2px solid #b6bbb6',
                            }}
                          >
                            <span className="fileExt">
                              {getFileExtensionFromUrl(file)}
                            </span>
                          </div>
                        )}
                        <div
                          className="imageRemoveButton"
                          style={{
                            textAlign: 'center',
                            position: 'absolute',
                            top: '-8px',
                            right: '0',
                            background: 'red',
                            borderRadius: '50%',
                            color: 'white',
                          }}
                        >
                          <div
                            onClick={() => {
                              let newList = selectedFiles.filter(
                                (f) => f !== file
                              );
                              setSelectedFiles(newList);
                            }}
                          >
                            <CancelOutlined />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
              {/* {uploadStatus === 'done' ? (
                    <p style={{ color: '#609426', fontWeight: 'bold' }}>
                      Your files are successfully uploaded!
                    </p>
                  ) : uploadStatus === 'uploading' ? (
                    <>
                      <div
                        style={{
                          width: '100%',
                          height: '8px',
                          backgroundColor: '#bbbbbb',
                          borderRadius: '4px',
                          marginBottom: '12px',
                        }}
                      >
                        <div
                          style={{
                            transition: '1.0s',
                            width:
                              (uploadCount * 100) / previewFiles.length + '%',
                            height: '8px',
                            backgroundColor: '#609426',
                            borderRadius: '4px',
                          }}
                        ></div>
                      </div>
                      <p>Uploading</p>
                    </>
                  ) : (
                    <></>
                  )} */}
            </div>
          </>
        </div>

        {/* {detail.designer_submitted_files &&
        detail.designer_submitted_files.length > 0 ? (
          <>
            <div
              className="u-head-text"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <ImageOutlined style={{ marginRight: '8px' }} />
              Submitted Files by Designer
              <span
                className="u-badge"
                style={{
                  marginLeft: '12px',
                  background:
                    detail.status === 'Rejected'
                      ? '#ffe6e6'
                      : detail.status === 'Waiting for review'
                      ? '#ffefe6'
                      : detail.status === 'Not assigned'
                      ? '#eaeaea'
                      : detail.status === 'Approved'
                      ? '#edffd9'
                      : detail.status === 'Finished'
                      ? '#cef9f5'
                      : '#d9d9ff',
                  color:
                    detail.status === 'Rejected'
                      ? '#d60f0f'
                      : detail.status === 'Waiting for review'
                      ? '#ad5119'
                      : detail.status === 'Not assigned'
                      ? '#949494'
                      : detail.status === 'Approved'
                      ? '#338036'
                      : detail.status === 'Finished'
                      ? '#287390'
                      : '#4d4dbf',
                }}
              >
                {detail.status}
              </span>
            </div>{' '}
            <ScrollableGallery filesList={detail.designer_submitted_files} />
          </>
        ) : (
          <></>
        )}

        {detail.design_files_link && detail.design_files_link.length > 0 ? (
          <>
            <div
              className="u-head-text"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              <LinkOutlined style={{ marginRight: '8px' }} />
              Submitted Link by Designer
              <span
                className="u-badge"
                style={{
                  marginLeft: '12px',
                  background:
                    detail.status === 'Rejected'
                      ? '#ffe6e6'
                      : detail.status === 'Waiting for review'
                      ? '#ffefe6'
                      : detail.status === 'Not assigned'
                      ? '#eaeaea'
                      : detail.status === 'Approved'
                      ? '#edffd9'
                      : detail.status === 'Finished'
                      ? '#cef9f5'
                      : '#d9d9ff',
                  color:
                    detail.status === 'Rejected'
                      ? '#d60f0f'
                      : detail.status === 'Waiting for review'
                      ? '#ad5119'
                      : detail.status === 'Not assigned'
                      ? '#949494'
                      : detail.status === 'Approved'
                      ? '#338036'
                      : detail.status === 'Finished'
                      ? '#287390'
                      : '#4d4dbf',
                }}
              >
                {detail.status}
              </span>
            </div>
            <div style={{ marginBottom: '24px' }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={detail.design_files_link}
              >
                {detail.design_files_link}
              </a>
            </div>
          </>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
}

export default ProjectDetailsEditView;
