import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import notFoundImage from '../../assets/404.gif';

function Error404() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <>
        <img src={notFoundImage} style={{ maxWidth: 'min(80%, 600px)' }} />
        <Button>
          <Link to="/">Back to Homepage</Link>
        </Button>
      </>
    </div>
  );
}

export default Error404;
