import '../../App.css';
import '../../css/Body.css';

import {
  Block,
  Check,
  InfoOutlined,
  PersonAdd,
  PersonAddDisabled,
} from '@material-ui/icons';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AccessOnly from '../../widgets/AccessOnly';
import MaterialTable from 'material-table';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import tableIcons from '../../table/Table-init';

const LandscapeClients = () => {
  const [users, setusers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    db.firestore()
      .collection('users')
      .where('user_role', '==', 'client')
      .onSnapshot(function (querySnapshot) {
        let loadedList = querySnapshot.docs.map((item) => item.data());
        setusers(loadedList);
        setIsLoading(false);
      });
  }, []);

  console.log('state users');
  console.log(users);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  return (
    <AccessOnly userType="admin">
      <div className="body">
        <MaterialTable
          isLoading={isLoading}
          title={`Landscape Clients (${users.length})`}
          className="Users__table"
          icons={tableIcons}
          data={users}
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            { title: 'Email', field: 'email' },
            {
              title: 'Phone',
              field: 'phone',
              render: (rowData) => {
                let phone = `tel:${rowData.phone}`;
                return <a href={phone}>{rowData.phone}</a>;
              },
            },

            {
              title: 'Company',
              field: 'company',

              // lookup: {
              //   true: <div className="u-badge badge__approved">Approved</div>,
              //   false: (
              //     <div className="u-badge badge__not_approved">Pending</div>
              //   ),
              //   '': <div className="u-badge badge__unknown">Unknown</div>,
              // },
            },

            {
              title: 'Location',
              field: 'address',
              render: (rowData) => {
                return (
                  <span>
                    {rowData.address}, {rowData.city}, {rowData.state} -
                    {rowData.zip}
                  </span>
                );
              },
            },
          ]}
          // actions={[
          //   (rowData) => ({
          //     hidden: rowData.is_admin === true,
          //     icon: () => (
          //       <>
          //         <PersonAdd style={{ color: 'green' }} />
          //       </>
          //     ),
          //     tooltip: 'Make Admin',
          //     onClick: (event, rowData) => {
          //       setIsLoading(true);
          //       makeAdmin(rowData.email).then((_) => setIsLoading(false));
          //     },
          //   }),
          //   (rowData) => ({
          //     hidden: rowData.is_admin !== true,
          //     icon: () => <PersonAddDisabled style={{ color: 'red' }} />,
          //     tooltip: 'Remove as Admin',
          //     onClick: (event, rowData) => {
          //       setIsLoading(true);
          //       removeAdmin(rowData.email).then((_) => setIsLoading(false));
          //     },
          //   }),
          // ]}

          options={{
            actionsColumnIndex: -1,
            pageSizeOptions: [20, 30, 40, 80, 100],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
        />
      </div>
    </AccessOnly>
  );
};

export default LandscapeClients;
