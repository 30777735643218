import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAVl0XN_xfeoAz4EsVyNuWKoVdO9qlhzuQ',
  authDomain: 'vista-concepts.firebaseapp.com',
  projectId: 'vista-concepts',
  storageBucket: 'vista-concepts.appspot.com',
  messagingSenderId: '419607791762',
  appId: '1:419607791762:web:5dcc1ff190060d6bedbe4b',
  measurementId: 'G-HBCC4V7HYG',
};

const db = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default db;
