import '../css/Header.css';

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Menu,
  Tooltip,
} from '@material-ui/core';
import {
  MenuOutlined,
  NotificationsOutlined,
  SettingsOutlined,
} from '@material-ui/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { UserContext } from '../App';
import db from '../firebase/config';
import logo from '../assets/logo.png';
import moment from 'moment';
import ProjectDetailsView from './new-projects/ProjectDetailsView';

const Header = ({ onMenuClick, setUserInfo, setProjectsList }) => {
  const userInfo = useContext(UserContext);

  const [projectsListState, setProjectsListState] = useState([]);
  const [notificationList, setNotificationList] = useState([]);

  var user = db.auth().currentUser;

  const logOut = () => {
    db.auth().signOut();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [fullName, setFullName] = useState('');
  const [emptyNotification, clearNotification] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    clearNotification(true);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const [isOpenDetailView, setIsOpenDetailView] = useState(false);

  const [scroll, setScroll] = useState('paper');

  const [currentRowData, setCurrentRowData] = useState({});

  const openProjectDetailModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setIsOpenDetailView(true);
    setScroll(scrollType);
  };

  const handleCloseView = () => {
    setIsOpenDetailView(false);
  };

  const getProjectDetailsById = (projectId) => {
    return projectsListState.find((item) => item.created === projectId);
  };

  const descriptionElementRefDetail = useRef(null);

  useEffect(() => {
    console.log('calling header state useEffect');
    let userData;

    var userSnapshot = db
      .firestore()
      .collection('users')
      .where('email', '==', user.email);

    userSnapshot.onSnapshot((snapShot) => {
      console.log('onSnapShotUser');
      snapShot.docs.map((item) => {
        userData = item.data();
        setUserInfo(item.data());
        setFullName(item.data().name);
      });

      //fetch projects
      userData.is_admin
        ? db
            .firestore()
            .collection('projects')
            .onSnapshot(function (collection) {
              let loadedList = collection.docs
                .map((doc) => doc.data())
                .reverse();

              setProjectsList(loadedList);
              setProjectsListState(loadedList);
            })
        : userData.user_role === 'client'
        ? db
            .firestore()
            .collection('projects')
            .where('company_name', '==', `${userData.company}`)

            .onSnapshot(function (collection) {
              let loadedList = collection.docs
                .map((doc) => doc.data())
                .reverse();
              setProjectsList(loadedList);
              setProjectsListState(loadedList);
            })
        : userData.user_role === 'designer'
        ? db
            .firestore()
            .collection('projects')

            .where('assigned_to_email', '==', `${userData.email}`)
            .onSnapshot(function (collection) {
              let loadedList = collection.docs
                .map((doc) => doc.data())
                .reverse();
              setProjectsList(loadedList);
              setProjectsListState(loadedList);
            })
        : console.log('fetching users');

      userData.is_admin
        ? db
            .firestore()
            .collection('notifications')
            .where('commentator_name', '!=', `${userData.name}`)
            .onSnapshot(function (collectionSnapShot) {
              let loadedList = collectionSnapShot.docs
                .map((doc) => doc.data())
                .reverse();
              setNotificationList(loadedList);
              console.log('notifications length ' + notificationList.length);
            })
        : console.log('under construction');
    });
  }, [
    notificationList.length,
    setProjectsList,
    setUserInfo,
    user.email,
    userInfo.name,
  ]);

  return (
    <>
      <div className="header">
        {/* detail dialog */}
        <Dialog
          className="projectDetail__dialog"
          open={isOpenDetailView}
          onClose={handleCloseView}
          scroll={scroll}
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-detail-description"
              ref={descriptionElementRefDetail}
              tabIndex={-1}
            >
              <ProjectDetailsView
                detail={currentRowData}
                closeDialog={handleCloseView}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div className="header__left">
          <Link to="/">
            <img src={logo} alt="logo" className="header__logo" />
          </Link>
          <div className="header__title">
            <h1>Vista Concepts</h1>
          </div>

          <IconButton className="header__MenuIcon" onClick={onMenuClick}>
            <MenuOutlined />
          </IconButton>
        </div>

        <div className="header__right" style={{ marginRight: '16px' }}>
          <IconButton onClick={handleNotificationClick}>
            {!emptyNotification ? <div className="notifyBall"></div> : <div />}
            <NotificationsOutlined />
          </IconButton>
          <IconButton>
            <SettingsOutlined />
          </IconButton>

          <IconButton onClick={handleClick}>
            {userInfo.avatar_url ? (
              <Avatar
                style={{ width: '24px', height: '24px' }}
                src={userInfo.avatar_url}
              />
            ) : (
              <Avatar style={{ width: '24px', height: '24px' }}>
                <b style={{ fontWeight: 'bold' }}>
                  {user.email[0].toUpperCase()}
                </b>
              </Avatar>
            )}
          </IconButton>

          <div className="header__notificationIcon">
            <Menu
              id="simple-menu"
              anchorEl={notificationAnchorEl}
              keepMounted
              open={Boolean(notificationAnchorEl)}
              onClose={handleNotificationClose}
            >
              <div className="header__notification">
                <h4>Notifications</h4>
                <div className="header_subHead">Primary</div>
                {userInfo.is_admin ? (
                  <div className="header__notificationAdmin">
                    {/* if empty */}
                    {projectsListState.filter(
                      (item) => item.status === 'Approved'
                    ).length > 0 &&
                      projectsListState.filter(
                        (item) => item.status === 'Waiting for review'
                      ).length === 0 &&
                      projectsListState.filter(
                        (item) => item.status === 'Not assigned'
                      ).length === 0 && (
                        <p className="noNotification">No Notifications!</p>
                      )}

                    {/* if not empty */}
                    {projectsListState.filter(
                      (item) => item.status === 'Not assigned'
                    ).length > 0 && (
                      <Link to="/projects/new">
                        <div
                          className="header__notificationRecord"
                          onClick={handleNotificationClose}
                        >
                          <div className="header__notificationRecordActive">
                            <p></p>
                          </div>
                          <p className="header__notificationText">
                            {
                              projectsListState.filter(
                                (item) => item.status === 'Not assigned'
                              ).length
                            }{' '}
                            new projects haven't assigned to any designer,
                            assign now!
                          </p>
                        </div>
                      </Link>
                    )}
                    {projectsListState.filter(
                      (item) => item.status === 'Waiting for review'
                    ).length > 0 && (
                      <Link to="/projects/queue">
                        <div
                          className="header__notificationRecord"
                          onClick={handleNotificationClose}
                        >
                          <div className="header__notificationRecordActive">
                            <p></p>
                          </div>
                          <p className="header__notificationText">
                            {
                              projectsListState.filter(
                                (item) => item.status === 'Waiting for review'
                              ).length
                            }{' '}
                            projects have been submitted by designer, but not
                            reviewed yet!
                          </p>
                        </div>
                      </Link>
                    )}
                    {projectsListState.filter(
                      (item) => item.status === 'Approved'
                    ).length > 0 && (
                      <Link to="/projects/approved">
                        <div
                          className="header__notificationRecord"
                          onClick={handleNotificationClose}
                        >
                          <div className="header__notificationRecordActive">
                            <p></p>
                          </div>
                          <p className="header__notificationText">
                            {
                              projectsListState.filter(
                                (item) => item.status === 'Approved'
                              ).length
                            }{' '}
                            projects have marked as approved, but haven't
                            finished yet!
                          </p>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : userInfo.user_role === 'client' ? (
                  <div className="header__notificationClient">
                    {/* if empty */}
                    {projectsListState.filter(
                      (item) => item.status === 'Waiting for review'
                    ).length === 0 && (
                      <p className="noNotification">No Notifications!</p>
                    )}

                    {/* if not empty */}
                    {projectsListState.filter(
                      (item) => item.status === 'Waiting for review'
                    ).length > 0 && (
                      <Link to="/projects/queue">
                        <div
                          className="header__notificationRecord"
                          onClick={handleNotificationClose}
                        >
                          <div className="header__notificationRecordActive">
                            <p></p>
                          </div>
                          <p className="header__notificationText">
                            {
                              projectsListState.filter(
                                (item) => item.status === 'Waiting for review'
                              ).length
                            }{' '}
                            projects have been submitted by designer, but not
                            reviewed yet!
                          </p>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : userInfo.user_role === 'designer' ? (
                  <div className="header__notificationDesigner">
                    {/* if empty */}
                    {projectsListState.filter(
                      (item) => item.status === 'Work in progress'
                    ).length === 0 &&
                      projectsListState.filter(
                        (item) => item.status === 'Rejected'
                      ).length === 0 && (
                        <p className="noNotification">No Notifications!</p>
                      )}

                    {/* if not empty */}
                    {projectsListState.filter(
                      (item) => item.status === 'Work in progress'
                    ).length > 0 && (
                      <Link to="/projects/queue">
                        <div
                          className="header__notificationRecord"
                          onClick={handleNotificationClose}
                        >
                          <div className="header__notificationRecordActive">
                            <p></p>
                          </div>
                          <p className="header__notificationText">
                            {
                              projectsListState.filter(
                                (item) => item.status === 'Work in progress'
                              ).length
                            }{' '}
                            projects have been assigned to you, submit fast
                            before the due date!
                          </p>
                        </div>
                      </Link>
                    )}
                    {projectsListState.filter(
                      (item) => item.status === 'Rejected'
                    ).length > 0 && (
                      <Link to="/projects/queue">
                        <div
                          className="header__notificationRecord"
                          onClick={handleNotificationClose}
                        >
                          <div className="header__notificationRecordActive">
                            <p></p>
                          </div>
                          <p className="header__notificationText">
                            {
                              projectsListState.filter(
                                (item) => item.status === 'Rejected'
                              ).length
                            }{' '}
                            projects have been rejected, view in design queue
                          </p>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : (
                  'Undefined User'
                )}
                {notificationList.length > 0 ? (
                  <div className="header_subHead">Most Recent</div>
                ) : (
                  <span />
                )}

                {notificationList.map((notification) => (
                  <div
                    key={notification.created}
                    className="header__newNotification"
                    onClick={openProjectDetailModal(
                      'paper',
                      getProjectDetailsById(notification.projectId)
                    )}
                  >
                    <span>
                      <strong>{notification.commentator_name}</strong> commented
                      on {notification.projectName}
                    </span>

                    <div>
                      {/* <span style={{ marginRight: '16px' }}>Reply</span> */}
                      <span
                        style={{
                          color: '#777777',
                          fontSize: 12,
                          padding: '14px 0px',
                        }}
                      >
                        {moment().diff(
                          moment.unix(notification.created / 1000),
                          'days'
                        ) <= 3 ? (
                          <Tooltip
                            title={moment
                              .unix(notification.created / 1000)
                              .format('MMM D, yyyy hh:mm a')}
                            arrow
                          >
                            <span>
                              {moment
                                .unix(notification.created / 1000)
                                .fromNow()}{' '}
                            </span>
                          </Tooltip>
                        ) : (
                          <span>
                            {moment
                              .unix(notification.created / 1000)
                              .format('MMM D, yyyy hh:mm a')}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Menu>
          </div>

          <div className="header__logout">
            {/* <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert />
            </Button> */}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div className="header__menuPopup">
                {userInfo.avatar_url ? (
                  <Avatar
                    style={{ width: '48px', height: '48px' }}
                    src={userInfo.avatar_url}
                  />
                ) : (
                  <Avatar style={{ width: '48px', height: '48px' }}>
                    <b style={{ fontWeight: 'bold', fontSize: '20px' }}>
                      {user.email[0].toUpperCase()}
                    </b>
                  </Avatar>
                )}
                <div
                  className="header__loggedInfo"
                  style={{
                    marginTop: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span className="header__userName">
                    <b style={{ color: '#404040', fontSize: '12px' }}>
                      {fullName}
                    </b>
                  </span>
                  <span className="header__userText">{user.email}</span>
                </div>
              </div>

              <Button
                className="button__actionDanger"
                style={{
                  width: '90%',
                  marginTop: '18px',
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
                onClick={logOut}
              >
                Log Out
              </Button>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
