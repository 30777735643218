import { Tooltip } from "@material-ui/core";
import React from "react";
import "../css/SideBarMenuItem.css";

const SideBarMenuItem = ({ Icon, title }) => {
 
  

  return (
    
    <>
      <div className="sideBarMenuItem">
        {<Tooltip title={title}><Icon className="sideBarMenuItem__icon"></Icon></Tooltip>}
        <div className="sideBarMenuItem__title"> {title}</div>
      </div>
    </>
  );
};

export default SideBarMenuItem;
