import { Avatar } from '@material-ui/core';
import React from 'react';

function ListTile({ data, onClick }) {
  return (
    <div
      onClick={onClick}
      className="ListTile"
      style={{
        width: '100%',
        height: '4.2rem',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          width: '15%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Avatar> {data.name[0]} </Avatar>
      </div>
      <div style={{ width: '85%', padding: '8px' }}>{data.name}</div>
    </div>
  );
}

export default ListTile;
