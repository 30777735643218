import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Snackbar } from '@material-ui/core';

function NotificationShow({ isNotificationOpen, setIsNotificationOpen }) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <Snackbar
      open={isNotificationOpen}
      autoHideDuration={5000}
      onClose={() => {
        setIsNotificationOpen(false);
      }}
    >
      <Alert
        onClose={() => {
          setIsNotificationOpen(false);
        }}
        severity="success"
      >
        Successfully done!
      </Alert>
    </Snackbar>
  );
}

export default NotificationShow;
