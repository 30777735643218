import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';

import DesignerList from './DesignerList';
import FormNewProjects from './FormNewProjects';
import MaterialTable from 'material-table';
import NoAccess from '../../widgets/NoAccess';
import NotificationShow from '../../widgets/NotificationShow';
import ProjectDetailsView from './ProjectDetailsView';
import { ProjectsListContext, UserContext } from '../../App';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import moment from 'moment';
import tableIcons from '../../table/Table-init';

const NewProjects = () => {
  let imageList = [];
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValueList, setSearchValueList] = useState([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');
  const [newProjectInfo, setNewProjectInfo] = useState({});

  const userInfo = useContext(UserContext);
  const projectsListInfo = useContext(ProjectsListContext);

  const OptionList = (props) => {
    return props.optionList.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  };

  const handleChange = (e) => {
    console.log('handleChange -> handleChange called');
    setSelectedSearchValue(e.target.value);
    let filteredLogList = projectList.filter((list) =>
      e.target.value === '' ? list : list.company_name === e.target.value
    );

    setFilteredList(filteredLogList);
    console.log(e.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let loadedList = [];

    if (userInfo.email) {
      if (projectsListInfo.length > 0) {
        loadedList = [...projectsListInfo].filter(
          (item) => item.status === 'Not assigned'
        );
        let loadedCompanyNameList = [
          ...new Set(loadedList.map((item) => item.company_name)),
        ];

        setSearchValueList(loadedCompanyNameList);
        setProjectList(loadedList);
        setFilteredList(loadedList);
      }
      setIsLoading(false);
    }
  }, [projectsListInfo, userInfo.email]);

  const [open, setOpen] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [openAssignView, setOpenAssignView] = useState(false);

  const [scroll, setScroll] = useState('paper');

  const [currentRowData, setCurrentRowData] = useState({});

  const [networkCalled, setNetworkCalled] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('initial');

  const openProjectDetailModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setOpenDetailView(true);
    setScroll(scrollType);
  };

  const openNewProjectForm = (scrollType) => () => {
    setNewProjectInfo({});

    setOpen(true);
    setScroll(scrollType);
  };

  const openAssignViewModal = (scrollType, rowData) => () => {
    setOpenAssignView(true);
    setCurrentRowData(rowData);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseView = () => {
    setOpenDetailView(false);
  };

  const closeAssignModal = () => {
    setOpenAssignView(false);
  };

  const handlePost = async () => {
    let count = 0;
    setNetworkCalled(true);

    let currentDate = moment().format('MMM D, yyyy hh:mm:ss a');
    let currentDateString = Date.now().toString();

    if (selectedFiles.length > 0) {
      setUploadStatus('uploading');
      selectedFiles.map((file) => {
        db.storage()
          .ref(currentDateString)
          .child(currentDateString + '-' + file.name)
          .put(file)
          .on(
            'state_changed',
            (progress) => {
              console.log('progress');
              console.log(progress);
            },
            (error) => {
              console.log('error');
              console.log(error);
            },
            (complete) => {
              console.log('complete');
              console.log(complete);
              db.storage()
                .ref(currentDateString)
                .child(currentDateString + '-' + file.name)
                .getDownloadURL()
                .then((imageUrl) => {
                  imageList = [...imageList, imageUrl];
                  count++;
                  setUploadCount(count);
                  if (selectedFiles.length === count) {
                    console.log('upload count must be same now');
                    db.firestore()
                      .collection('projects')
                      .doc(currentDateString)
                      .set(
                        {
                          created: currentDateString,
                          posted_on: currentDate,
                          project_files: imageList,
                          is_assigned: false,
                          status: 'Not assigned',
                          company_name: userInfo.company,
                          posted_by_name: userInfo.name,
                          posted_by_email: userInfo.email,
                          project_name: newProjectInfo.project_name,
                          project_desc: newProjectInfo.project_desc,
                          project_type: newProjectInfo.project_type
                            ? newProjectInfo.project_type
                            : '3D',
                          project_due_date: newProjectInfo.project_due_date,
                          project_map_location:
                            newProjectInfo.project_map_location
                              ? newProjectInfo.project_map_location
                              : '',
                          project_files_link: newProjectInfo.project_files_link
                            ? newProjectInfo.project_files_link
                            : '',
                        },
                        { merge: true }
                      )
                      .then(() => {
                        setTimeout(() => {
                          setNetworkCalled(false);
                          setUploadStatus('done');
                          setIsNotificationOpen(true);
                          setTimeout(() => {
                            setOpen(false);
                            setUploadCount(0);
                            setSelectedFiles([]);
                            setUploadStatus('initial');
                          }, 300);
                        }, 800);
                      });
                  }
                });
            }
          );
      });
    } else {
      await db
        .firestore()
        .collection('projects')
        .doc(currentDateString)
        .set({
          created: currentDateString,
          posted_on: currentDate,
          is_assigned: false,
          status: 'Not assigned',
          company_name: userInfo.company,
          posted_by_name: userInfo.name,
          posted_by_email: userInfo.email,
          project_name: newProjectInfo.project_name,
          project_desc: newProjectInfo.project_desc,
          project_type: newProjectInfo.project_type
            ? newProjectInfo.project_type
            : '3D',
          project_due_date: newProjectInfo.project_due_date,
          project_map_location: newProjectInfo.project_map_location
            ? newProjectInfo.project_map_location
            : '',
          project_files_link: newProjectInfo.project_files_link
            ? newProjectInfo.project_files_link
            : '',
        });
      setNetworkCalled(false);
      setIsNotificationOpen(true);
      setOpen(false);
    }
  };

  const descriptionElementRef = useRef(null);
  const descriptionElementRefDetail = useRef(null);
  const descriptionElementRefAssign = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  return (
    <NoAccess userType="designer">
      <div className="body">
        <NotificationShow
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
        />

        <div className="body__toolbar">
          <FormControl className="log__selectRoot">
            {userInfo.user_role !== 'Client' && (
              <>
                <InputLabel htmlFor="age-native-simple">
                  Filter by Company{' '}
                </InputLabel>
                <Select
                  native
                  value={selectedSearchValue}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <OptionList optionList={searchValueList} />
                </Select>
              </>
            )}
          </FormControl>

          <Button
            variant="contained"
            className="newProjects__postButton"
            onClick={openNewProjectForm('paper')}
          >
            Post a new project
          </Button>

          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              {newProjectInfo.project_name ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      marginRight: '12px',
                      borderRadius: '4px',
                      padding: '2px 6px',
                      backgroundColor: '#609426',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {newProjectInfo.project_type
                      ? newProjectInfo.project_type
                      : '3D'}
                  </span>{' '}
                  <span
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: '#609426',
                    }}
                  >
                    {newProjectInfo.project_name}
                  </span>
                </div>
              ) : (
                <>Post a new project</>
              )}
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <div
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <FormNewProjects
                  newProjectInfo={newProjectInfo}
                  setNewProjectInfo={setNewProjectInfo}
                  setSelectedFiles={setSelectedFiles}
                  uploadCount={uploadCount}
                  uploadStatus={uploadStatus}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {!networkCalled ? (
                <Button
                  style={{ margin: '4px 10px', width: '100px' }}
                  variant="contained"
                  onClick={handlePost}
                  disabled={
                    newProjectInfo.project_name &&
                    newProjectInfo.project_desc &&
                    newProjectInfo.project_due_date &&
                    newProjectInfo.project_map_location
                      ? false
                      : true
                  }
                  color="primary"
                >
                  Post
                </Button>
              ) : (
                <div style={{ padding: '0 58px' }}>
                  <CircularProgress size={26} />
                </div>
              )}
            </DialogActions>
          </Dialog>
        </div>

        {/* detail dialog */}
        <Dialog
          className="projectDetail__dialog"
          open={openDetailView}
          onClose={handleCloseView}
          scroll={scroll}
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-detail-description"
              ref={descriptionElementRefDetail}
              tabIndex={-1}
            >
              <ProjectDetailsView
                detail={currentRowData}
                closeDialog={handleCloseView}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* assign a designer dialog */}
        <Dialog
          class="u-small-dialog"
          open={openAssignView}
          onClose={closeAssignModal}
          scroll={scroll}
          aria-labelledby="scroll-dialog-assign-title"
          aria-describedby="scroll-dialog-assign-description"
        >
          <DialogTitle id="scroll-dialog-assign-title">
            Assign a designer
          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-assign-description"
              ref={descriptionElementRefAssign}
              tabIndex={-1}
            >
              <DesignerList
                currentProject={currentRowData}
                setIsNotificationOpen={setIsNotificationOpen}
                setOpenAssignView={setOpenAssignView}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAssignModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          title={`New Projects (${filteredList.length})`}
          className="body__table"
          icons={tableIcons}
          data={filteredList}
          columns={[
            {
              title: 'Project Name',
              field: 'project_name',
              render: (rowData) => (
                <>
                  <div
                    onClick={openProjectDetailModal('paper', rowData)}
                    className="rowProject__Title"
                  >
                    <span
                      style={{
                        fontSize: '11px',
                        marginRight: '12px',
                        borderRadius: '4px',
                        padding: '3px',
                        backgroundColor: rowData.project_type
                          ? rowData.project_type === '3D'
                            ? '#00a006'
                            : '#8c9da5'
                          : '#00a006',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {rowData.project_type ? rowData.project_type : '3D'}
                    </span>
                    <span>{rowData.project_name}</span>
                  </div>
                </>
              ),
            },

            {
              title: 'Company Name',
              field: 'company_name',
            },

            {
              title: 'Posted on',
              field: 'posted_on',
            },

            {
              title: 'Due Date',
              field: 'project_due_date',

              render: (rowData) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      backgroundColor:
                        moment(rowData.project_due_date, 'MMM D, yyyy').diff(
                          moment(),
                          'hours'
                        ) < 1
                          ? 'red'
                          : '#26cc26',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />

                  <span>{rowData.project_due_date}</span>
                </div>
              ),
            },

            userInfo.user_role && userInfo.user_role === 'admin'
              ? {
                  title: 'Actions',
                  export: false,
                  field: 'project_name',
                  render: (rowData) => {
                    return (
                      <>
                        <Button
                          className="u-rowButton"
                          style={{ outline: 'none', margin: '1px' }}
                          variant="outlined"
                          onClick={openProjectDetailModal('paper', rowData)}
                        >
                          View
                        </Button>
                        <Button
                          className="u-rowButton button__action"
                          variant="outlined"
                          onClick={openAssignViewModal('paper', rowData)}
                        >
                          Assign
                        </Button>
                      </>
                    );
                  },
                }
              : {
                  title: 'Actions',
                  export: false,
                  field: 'project_name',
                  render: (rowData) => {
                    return (
                      <>
                        <Button
                          className="u-rowButton"
                          style={{ outline: 'none', margin: '1px' }}
                          variant="outlined"
                          onClick={openProjectDetailModal('paper', rowData)}
                        >
                          View
                        </Button>
                      </>
                    );
                  },
                },
          ]}
          options={{
            exportButton: true,
            pageSizeOptions: [20, 30, 40, 60],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
          isLoading={isLoading}
        />
      </div>
    </NoAccess>
  );
};

export default NewProjects;
