import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';

import MaterialTable from 'material-table';
import ProjectDetailsView from '../new-projects/ProjectDetailsView';
import { ProjectsListContext, UserContext } from '../../App';
import db from '../../firebase/config';
import hStyle from '../../assets/headerStyle';
import tableIcons from '../../table/Table-init';

const FinishedProjects = () => {
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValueList, setSearchValueList] = useState([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');

  const userInfo = useContext(UserContext);
  const projectsListInfo = useContext(ProjectsListContext);

  const [openDetailView, setOpenDetailView] = useState(false);

  const [scroll, setScroll] = useState('paper');

  const [currentRowData, setCurrentRowData] = useState({});

  const OptionList = (props) => {
    return props.optionList.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  };

  const handleChange = (e) => {
    console.log('handleChange -> handleChange called');
    setSelectedSearchValue(e.target.value);
    let filteredLogList = projectList.filter((list) =>
      e.target.value === '' ? list : list.company_name === e.target.value
    );

    setFilteredList(filteredLogList);
    console.log(e.target.value);
  };

  const openProjectDetailModal = (scrollType, rowData) => () => {
    setCurrentRowData(rowData);
    setOpenDetailView(true);
    setScroll(scrollType);
  };

  const handleCloseView = () => {
    setOpenDetailView(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedList = [];
    if (userInfo.email) {
      if (projectsListInfo.length > 0) {
        loadedList = [...projectsListInfo].filter(
          (item) => item.status === 'Finished'
        );
        let loadedCompanyNameList = [
          ...new Set(loadedList.map((item) => item.company_name)),
        ];

        setSearchValueList(loadedCompanyNameList);
        setProjectList(loadedList);
        setFilteredList(loadedList);
      }
      setIsLoading(false);
    }
  }, [projectsListInfo, userInfo.email]);

  const descriptionElementRefDetail = useRef(null);

  return (
    <>
      <div className="body">
        <div className="body__toolbar">
          <FormControl className="log__selectRoot">
            <InputLabel htmlFor="age-native-simple">
              Filter by Company{' '}
            </InputLabel>
            <Select
              native
              value={selectedSearchValue}
              onChange={handleChange}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <OptionList optionList={searchValueList} />
            </Select>
          </FormControl>
        </div>

        {/* detail dialog */}
        <Dialog
          className="projectDetail__dialog"
          open={openDetailView}
          onClose={handleCloseView}
          scroll={scroll}
          aria-labelledby="scroll-dialog-detail-title"
          aria-describedby="scroll-dialog-detail-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
            <div
              id="scroll-dialog-detail-description"
              ref={descriptionElementRefDetail}
              tabIndex={-1}
            >
              <ProjectDetailsView
                detail={currentRowData}
                closeDialog={handleCloseView}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          title={`Finished Projects (${filteredList.length})`}
          className="body__table"
          icons={tableIcons}
          data={filteredList}
          columns={[
            {
              title: 'Project Name',
              field: 'project_name',
              render: (rowData) => (
                <div
                  onClick={openProjectDetailModal('paper', rowData)}
                  className="rowProject__Title"
                >
                  <span
                    style={{
                      fontSize: '11px',
                      marginRight: '12px',
                      borderRadius: '4px',
                      padding: '3px',
                      backgroundColor: rowData.project_type
                        ? rowData.project_type === '3D'
                          ? '#00a006'
                          : '#8c9da5'
                        : '#00a006',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {rowData.project_type ? rowData.project_type : '3D'}
                  </span>
                  <span>{rowData.project_name}</span>
                </div>
              ),
            },

            {
              title: 'Company Name',
              field: 'company_name',
            },

            { title: 'Due Date', field: 'project_due_date' },

            { title: 'Assigned to', field: 'assigned_to_name' },

            {
              title: 'Status',
              field: 'status',
              render: (rowData) => (
                <span
                  className="u-badge"
                  style={{
                    background: '#cef9f5',
                    color: '#287390',
                  }}
                >
                  {rowData.status}
                </span>
              ),
            },
          ]}
          options={{
            exportButton: true,
            pageSizeOptions: [20, 30, 40, 60],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: hStyle,
          }}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default FinishedProjects;
