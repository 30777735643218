import {
  CheckCircle,
  CheckCircleOutline,
  CheckCircleOutlined,
  DescriptionOutlined,
  ImageOutlined,
  LinkOutlined,
} from '@material-ui/icons';

import { Avatar } from '@material-ui/core';
import React from 'react';
import moment from 'moment';

function PaymentDetailsView({ detail }) {
  return (
    <>
      <div>
        <div>
          <h4
            style={{
              color: '#609426',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CheckCircle />
            <span style={{ marginLeft: '8px', fontSize: '18px' }}>Payment</span>

            <span
              className="u-badge"
              style={{
                marginLeft: '12px',
                background: '#edffd9',
                color: '#338036',
              }}
            >
              Paid
            </span>
          </h4>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginLeft: '30px' }}>
              {moment().diff(moment(detail.payment_time), 'minutes') < 60
                ? moment().diff(moment(detail.payment_time), 'minutes') +
                  ' minutes ago'
                : moment().diff(moment(detail.payment_time), 'hours') < 24
                ? moment().diff(moment(detail.payment_time), 'hours') +
                  ' hours ago'
                : moment(detail.payment_time).format('MMM d, yyyy')}
            </span>
            <span>
              <>
                <span
                  className="u-badge"
                  style={{
                    background: '#0070ba',
                    color: 'white',
                  }}
                >
                  Paypal
                </span>{' '}
                <span
                  style={{
                    color: '#3c8a51',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {detail.project_due_date}
                </span>
              </>
            </span>
          </div>

          <hr />
        </div>
        <b
          className="u-head-text"
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <DescriptionOutlined style={{ marginRight: '8px' }} />
          Details
        </b>
        <table className="dashboard__table">
          <tbody>
            <tr>
              <td>Amount</td>
              <td>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: '#609426',
                  }}
                >
                  {detail.paid_amount}
                </span>
              </td>
            </tr>
            <tr>
              <td>Paid by</td>
              <td>{detail.paid_by}</td>
            </tr>
            <tr>
              <td>Email Paypal Account</td>
              <td>{detail.payer_paypal_email}</td>
            </tr>

            <tr>
              <td>Name on Paypal Account</td>
              <td>{detail.payer_paypal_name}</td>
            </tr>
            <tr>
              <td>Trx ID</td>
              <td>{detail.trx_id}</td>
            </tr>
            <tr>
              <td>Paid at</td>
              <td>
                {moment(detail.payment_time).format('MMM D, yyyy, hh:mm:ss a')}
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>Paid</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default PaymentDetailsView;
