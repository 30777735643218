import '../../css/Login.css';

import { AccountCircle, AccountCircleOutlined } from '@material-ui/icons';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import db from '../../firebase/config';
import feature from '../../assets/vista-admin-feature.png';
import logo from '../../assets/logo.png';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
  return (
    <p align="center" style={{ color: '#609426' }} class="u-animate-fade">
      {'Copyright '}
      {new Date().getFullYear()}
      {'  '}
      <Link
        style={{ fontWeight: 'bold' }}
        target="_blank"
        href="https://vistaconcepts.co/"
      >
        <b style={{ color: '#609426' }}>Vista Concepts</b>
      </Link>

      {' | '}
      {'Powered by '}

      <Link
        style={{ fontWeight: 'bold' }}
        target="_blank"
        href="https://heavytask.com/"
      >
        <b style={{ color: '#609426' }}>HeavyTask</b>
      </Link>
    </p>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },

  logo: {
    borderRadius: '30px',
  },

  image: {
    backgroundImage:
      'url(https://vistaconcepts.co/wp-content/uploads/2021/04/work-15.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [didClickForgetPassword, setDidClickForgetPassword] = useState(false);

  const login = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setErrorMessage('');

    console.log('trying to login');
    await db
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((e) => setErrorMessage(e.message));

    // .then((auth) => {})
    // .catch((e) => alert(e.message));
    setIsLoading(false);
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    console.log('trying to reset');
    await db
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => setErrorMessage('Check your mail inbox'))
      .catch((e) => setErrorMessage(e.message));
    setIsLoading(false);
  };

  const onClickForgetPassword = () => {
    setErrorMessage('');
    setDidClickForgetPassword(true);
  };

  const onClickSignIn = () => {
    setErrorMessage('');
    setDidClickForgetPassword(false);
  };

  return (
    <>
      <div
        className="u-animate-slideDown centerItem"
        style={{ backgroundColor: '#fafafa' }}
      >
        <div className="login__card">
          <img
            src={logo}
            width="100"
            style={{ padding: '8px', borderRadius: '30px' }}
          />

          <h1
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: '#474747',
              textAlign: 'center',
            }}
          >
            Welcome to{' '}
            <span
              style={{
                color: 'var(--brand-color, )',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              Vista Concepts
            </span>
          </h1>

          <form className={classes.form} noValidate>
            <TextField
              required
              fullWidth
              label="Email Address"
              autoComplete="false"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              id="email"
            />
            <TextField
              style={{
                transition: '0.3s',
                opacity: didClickForgetPassword ? '0' : '1',
              }}
              margin="normal"
              required
              fullWidth
              label="Password"
              autoComplete="false"
              value={password}
              onChange={(event) => setpassword(event.target.value)}
              type="password"
              id="password"
            />

            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

            <div
              className="error-message"
              style={{
                transition: '0.5s',
                marginTop: didClickForgetPassword ? '-64px' : '0',
              }}
            >
              {errorMessage}
            </div>
            <div className="height-12"></div>
            <div className="button-wraper">
              {isLoading ? (
                <CircularProgress className={classes.submit} />
              ) : !didClickForgetPassword ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  onClick={login}
                >
                  Sign In
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  onClick={resetPassword}
                >
                  Reset Password
                </Button>
              )}
              <div className="auth__container">
                <p>
                  {!didClickForgetPassword ? (
                    <Link
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={onClickForgetPassword}
                    >
                      Forgot password?
                    </Link>
                  ) : (
                    <Link
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={onClickSignIn}
                    >
                      ← Back to Sign in Form
                    </Link>
                  )}
                </p>
                <p>
                  Don't have an account?{' '}
                  <Link style={{ fontWeight: 'bold' }} href="/signup">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
            {/* <Box mt={2}>
              <Copyright />
            </Box> */}
          </form>
        </div>
      </div>

      <div
        // class="u-animate-fade"
        style={{
          width: '100%',
          position: 'fixed',
          bottom: '0',
          left: '0',
          right: '0',
          height: '44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // background: '#e9eae9',
        }}
      >
        <Copyright />
      </div>
    </>
  );
}
